import { Component, ViewChild } from "@angular/core";
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormsModule,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { Event } from "src/app/models/event";
import { TranslateService } from "@ngx-translate/core";
import { TypeModule } from "src/app/enums/type-module";
import { environment } from "src/environments/environment";
import { DbManagerModuleProvider } from "src/app/providers/database/db-manager-module";
import { NameModule } from "src/app/models/name-module";
import { ModuleAttendee } from "src/app/models/modules/module-attendees";
import { ModuleSpeaker } from "src/app/models/modules/module-speaker";
import { ModuleSchedule } from "src/app/models/modules/module-schedule";
import { ModuleWidget } from "src/app/models/modules/module-widget";
import { ModuleLocation } from "src/app/models/modules/module-location";
import { ModuleMaps } from "src/app/models/modules/module-maps";
import { ModuleRanking } from "src/app/models/modules/module-ranking";
import { ModulePersonalAgenda } from "src/app/models/modules/module-personal-agenda";
import { ModuleGroup } from "src/app/models/modules/module-groups";
import { ManagerModules } from "src/app/models/modules/module-manager-module";
import { ModuleDocuments } from "src/app/models/modules/module-documents";
import { ModuleGallery } from "src/app/models/modules/module-gallery";
import { ModuleCheckin } from "src/app/models/modules/module-checkin";
import { ModuleSelfCheckin } from "src/app/models/modules/module-self-checkin";
import { ModuleNotification } from "src/app/models/modules/module-notifications";
import { ModuleEvent } from "src/app/models/modules/module-event";
import { ModuleSurvey } from "src/app/models/modules/module-survey";
import { ModuleQuiz } from "src/app/models/modules/module-quiz";
import { ModuleSessionFeedback } from "src/app/models/modules/module-session-feedback";
import { ModuleAskQuestion } from "src/app/models/modules/module-ask-question";
import { ModuleInteractivity } from "src/app/models/modules/module-interacvity";
import { ModuleFeedNews } from "src/app/models/modules/module-feed-news";
import { ModuleInfobooth } from "src/app/models/modules/module-infoobooth";
import { ModuleWordCloud } from "src/app/models/modules/module-word-cloud";
import { ModuleCustomPage } from "src/app/models/modules/module-custom-page";
import { ModuleGamification } from "src/app/models/modules/module-gamification";
import { PathIcons } from "src/app/paths/path-icons";
import { TypeVisionGroup } from "src/app/enums/type-vision-group";
import { TypeVisionModule } from "src/app/enums/type-vision-module";
import { GlobalService } from "src/app/providers/global/global.service";
import { AttendeeFields } from "src/app/models/attendee-fields";
import { ModuleGroupDiscussion } from "src/app/models/modules/module-group-discussion";
import { ModuleAppointments } from "src/app/models/modules/module-appointments";
import { DbAppointments } from "src/app/providers/database/db-appointments";
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { ModuleSponsor } from "src/app/models/modules/module-sponsor";
import { ModuleStand } from "src/app/models/modules/module-stand";
import { ModuleLeads } from "src/app/models/modules/module-leads";
import { ModuleRooms } from "src/app/models/modules/module-rooms";

@Component({
    selector: "app-event-b3-edit",
    templateUrl: "./event-b3-edit.component.html",
    styleUrls: ["./event-b3-edit.component.scss"],
})
export class EventB3EditComponent {
    formValidation: FormGroup;
    eventId: string = this.route.snapshot.params["uid"];
    invalidShortcodeError: boolean = false;
    modulesAvailable = [];
    qtdModels = 0;

    eventEdit: Event;

    // Variable Forms Error
    dateError: boolean;
    startDatePastError: boolean;
    endDatePastError: boolean;
    updateEventError: boolean;

    defaultAppUrl: string = environment.platform.defaultAppUrl;
    loader: boolean;

    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;
    @ViewChild("deleteSwal") public deleteSwal: SwalComponent;

    allowChangeApiId: boolean = false;
    allowChangeAppIdOneSignal: boolean = false;

    nameModuleAttendee = new NameModule("Participantes", "Attendees", "Participantes", "Participants", "Teilnehmer");
    nameModuleSpeaker = new NameModule("Palestrantes", "Speakers", "Oradores", "Oradores", "Lautsprecher");
    nameModuleSchedule = new NameModule("Agenda", "Schedule", "Horario", "Agenda", "Zeitplan");
    nameModuleWidget = new NameModule("Widget", "Widget", "Widget", "Widget", "Widget");
    nameModuleDocument = new NameModule("Documentos", "Documents", "Documentos", "Documents", "Unterlagen");
    nameModuleGallery = new NameModule("Galeria", "Gallery", "Galeria", "Galerie", "Galerie");
    nameModuleCheckin = new NameModule("Checkin", "Checkin", "Checkin", "Checkin", "Checkin");
    nameModuleMap = new NameModule("Mapas", "Maps", "Mapas", "Plans", "Karten");
    nameModuleRanking = new NameModule("Ranking", "Ranking", "Ranking", "Ranking", "Ranking");
    nameModuleSurvey = new NameModule("Pesquisa de Opinião","Survey","Survey","Survey","Survey");
    nameModuleQuiz = new NameModule("Quiz","Quiz","Quiz","Quiz","Quiz");
    nameModuleTraining = new NameModule("Training","Training","Training","Training","Training");
    nameModuleSessionFeedBack = new NameModule("Feedback da Sessão","Session Feedback","Comentarios de la sesión","Feedback des sessions","Sitzungs-Feedback");
    nameModuleAskQuestion = new NameModule("Faça uma Pergunta","Ask a question","Haga una pregunta","Poser une question","Stellen Sie eine Frage");
    nameModuleNewsFeed = new NameModule("Feed de Notícias","News feed","News Feed","Fil d'actualités","News Feed");
    nameModuleInfobooth = new NameModule("Infobooth","Infobooth","Infobooth","Infobooth","Infobooth");
    nameModuleCustomPage = new NameModule("Páginas Customizadas","Custom Pages","Páginas personalizadas","Liste de pages HTML","Benutzerdefinierte Seiten");
    nameModuleLocation = new NameModule("Localização","Location","Ubicación","Localisation","Lage");
    nameModulePersonalAgenda = new NameModule("Agenda Pessoal","Personal schedule","Agenda personal","Agenda Personnel","Persönliche Agenda");
    nameModuleSelfeCheckin = new NameModule("Auto checkin","Self checkin","Registro automático","Self checkin","Automatisches Einchecken");
    nameModuleEvent = new NameModule("Evento","Event","Evento","Événement","Evento");
    nameModuleGroup = new NameModule("Grupos","Groups","Grupos","Groupes","Gruppen");
    nameModuleManager = new NameModule("Módulos","Module","Módulos","Modules","Module");
    nameModuleNotifications = new NameModule("Notificações","Notifications","Notificaciones","Push Notifications","Benachrichtigungen");
    nameModuleInteractivity = new NameModule("Interatividade","Interactivity","Interactividad","Interactivité","Interaktivität");
    nameModuleWordCloud = new NameModule("Nuvem de palavras","Word cloud","Nube de palabras","Mot nuage","Wortwolke");
    nameModuleGamification = new NameModule("Gamificação","Gamification","Gamificación","Gamification","Gamification");
    nameModuleGroupDiscussion = new NameModule("Bate-papo em grupo","Discussion group","Discusión grupal","Discussion de groupe","Gruppendiskussion");
    nameModuleAppointments = new NameModule("Encontro","Appointment","Cita","Rendez-vous","Verabredung");
    nameModulePrizeDraw = new NameModule("Sorteio de prêmios","Prize draw","Sorteo de premios","tirage au sort","Gewinnspiel");
    nameModuleSponsor = new NameModule("Patrocinadores","Sponsors","Patrocinadores","Sponsors","Sponsoren",);
    nameModuleStand = new NameModule("Estandes","Stands","Stands","Stands","Stands",);
    nameModuleLeads = new NameModule("Leads","Leads","Leads","Leads","Leads");
    nameModuleRooms = new NameModule("Quartos", "Rooms", "Habitaciones", "Chambres", "Schlafzimmer");

    public modules: Array<any> = [];
    public moduleAttendee: ModuleAttendee;
    public moduleSpeaker: ModuleSpeaker;
    public moduleSchedule: ModuleSchedule;
    public moduleWidget: ModuleWidget;
    public moduleLocation: ModuleLocation;
    public moduleMaps: ModuleMaps;
    public moduleRanking: ModuleRanking;
    public modulePersonalAgenda: ModulePersonalAgenda;
    public moduleGroups: ModuleGroup;
    public moduleManager: ManagerModules;
    public moduleDocuments: ModuleDocuments;
    public moduleGallery: ModuleGallery;
    public moduleCheckin: ModuleCheckin;
    public moduleSelfCheckin: ModuleSelfCheckin;
    public moduleNotifications: ModuleNotification;
    public moduleEvent: ModuleEvent;
    public moduleSurvey: ModuleSurvey;
    public moduleWordCloud: ModuleWordCloud;
    public moduleSponsor: ModuleSponsor;
    public moduleStand: ModuleStand;
    public moduleQuiz: ModuleQuiz;
    public moduleSessionFeedback: ModuleSessionFeedback;
    public moduleAskQuestion: ModuleAskQuestion;
    public moduleInteractivity: ModuleInteractivity;
    public moduleFeedNews: ModuleFeedNews;
    public moduleInfobooth: ModuleInfobooth;
    public moduleCustomPage: ModuleCustomPage;
    public moduleGamification: ModuleGamification;
    public moduleGroupDiscussion: ModuleGroupDiscussion;
    public moduleAppointments: ModuleAppointments;
    public moduleLeads: ModuleLeads;
    public moduleRooms: ModuleRooms;

    public userLanguage: string; //Saves the user's language.

    constructor(
        private dbEvent: DbEventsProvider,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private translateService: TranslateService,
        private dbModule: DbManagerModuleProvider,
        private global: GlobalService,
        private dbAppointments: DbAppointments,
        private dt: DateTimeService
    ) {
        // get user language
        this.global.getLanguage((language) => {
            this.userLanguage = language;
        });
        this.dateError = false;
        this.startDatePastError = false;
        this.endDatePastError = false;
        this.updateEventError = false;

        this.loader = false;

        this.formValidation = this.fb.group({
            title: [
                this.translateService.instant("comp.new_event.event_name"),
                Validators.compose([Validators.required]),
            ],
            shortcode: [
                this.translateService.instant("comp.new_event.event_shortcode"),
                Validators.compose([Validators.required]),
            ],
            startDate: [
                "dd/mm/aaaa",
                Validators.compose([Validators.required]),
            ],
            endDate: ["dd/mm/aaaa", Validators.compose([Validators.required])],
            visibility: ["", Validators.compose([Validators.required])],
            allowChatUse: ["", Validators.compose([Validators.required])],
            allowVisioUse: [false, Validators.compose([Validators.required])],
            allowAppointments: [
                false,
                Validators.compose([Validators.required]),
            ],
            userLimit: [80, Validators.compose([Validators.required])],
            gallery_file_limit: [2048000],
            documents_file_limit: [3072000],
            notification_api_id: [null],
            notification_app_id: [null],
        });

        this.moduleAttendee = new ModuleAttendee(
            this.nameModuleAttendee,
            PathIcons.icon_people,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleSpeaker = new ModuleSpeaker(
            this.nameModuleSpeaker,
            PathIcons.icon_mic,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleSponsor = new ModuleSponsor(
            this.nameModuleSponsor,
            PathIcons.icon_sponsor,
            TypeVisionModule.GLOBAL_VISION,
            "",
            null,
        );
        this.moduleStand = new ModuleStand(
            this.nameModuleStand,
            PathIcons.icon_stand,
            TypeVisionModule.GLOBAL_VISION,
            "",
            null,
        );
        this.moduleSchedule = new ModuleSchedule(
            this.nameModuleSchedule,
            PathIcons.icon_date_range,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleWidget = new ModuleWidget(
            this.nameModuleWidget,
            PathIcons.icon_view_agenda,
            null,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleDocuments = new ModuleDocuments(
            this.nameModuleDocument,
            PathIcons.icon_document,
            null,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleGallery = new ModuleGallery(
            this.nameModuleGallery,
            PathIcons.icon_gallery,
            null,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleCheckin = new ModuleCheckin(
            this.nameModuleCheckin,
            PathIcons.icon_checkin,
            TypeVisionGroup.GROUP_ACCESS_PERMISSION,
            "",
            null
        );
        this.moduleMaps = new ModuleMaps(
            this.nameModuleMap,
            PathIcons.icon_map,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleRanking = new ModuleRanking(
            this.nameModuleRanking,
            PathIcons.icon_ranking,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleSurvey = new ModuleSurvey(
            this.nameModuleSurvey,
            PathIcons.icon_survey,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleQuiz = new ModuleQuiz(
            this.nameModuleQuiz,
            PathIcons.icon_quiz,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleSessionFeedback = new ModuleSessionFeedback(
            this.nameModuleSessionFeedBack,
            PathIcons.icon_session_feedback,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleAskQuestion = new ModuleAskQuestion(
            this.nameModuleAskQuestion,
            PathIcons.icon_ask_question,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleFeedNews = new ModuleFeedNews(
            this.nameModuleNewsFeed,
            PathIcons.icon_feed,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleInfobooth = new ModuleInfobooth(
            this.nameModuleInfobooth,
            PathIcons.icon_infobooth,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleCustomPage = new ModuleCustomPage(
            this.nameModuleCustomPage,
            PathIcons.icon_infobooth,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleGamification = new ModuleGamification(
            this.nameModuleGamification,
            PathIcons.icon_android_phone,
            "",
            TypeVisionGroup.GLOBAL_VISION,
            null
        );
        this.moduleGroupDiscussion = new ModuleGroupDiscussion(
            this.nameModuleGroupDiscussion,
            PathIcons.icon_people,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleAppointments = new ModuleAppointments(
            this.nameModuleAppointments,
            PathIcons.icon_calendar_today,
            TypeVisionModule.GLOBAL_VISION,
            null,
            null
        );
        this.moduleLeads = new ModuleLeads(
            this.nameModuleLeads,
            PathIcons.icon_leads,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleRooms = new ModuleRooms(
            this.nameModuleRooms,
            PathIcons.icon_rooms,
            TypeVisionModule.GLOBAL_VISION,
            null,
            null
        );

        // fixed modules
        this.moduleLocation = new ModuleLocation(
            this.nameModuleLocation,
            PathIcons.icon_map,
            null
        );
        this.modulePersonalAgenda = new ModulePersonalAgenda(
            this.nameModulePersonalAgenda,
            PathIcons.icon_calendar_today,
            "",
            null
        );
        this.moduleSelfCheckin = new ModuleSelfCheckin(
            this.nameModuleSelfeCheckin,
            PathIcons.icon_smartphone,
            null,
            null
        );
        this.moduleEvent = new ModuleEvent(
            this.nameModuleEvent,
            PathIcons.icon_event,
            null,
            null
        );
        this.moduleGroups = new ModuleGroup(
            this.nameModuleGroup,
            PathIcons.icon_group,
            null
        );
        this.moduleManager = new ManagerModules(
            this.nameModuleManager,
            PathIcons.icon_settings,
            null
        );
        this.moduleNotifications = new ModuleNotification(
            this.nameModuleNotifications,
            PathIcons.icon_notications,
            null
        );
        this.moduleInteractivity = new ModuleInteractivity(
            this.nameModuleInteractivity,
            PathIcons.icon_interactivity,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleWordCloud = new ModuleWordCloud(
            this.nameModuleWordCloud,
            PathIcons.icon_word_cloud,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );

        this.loadEvent();
        this.loadModules();
    }

    ngOnInit() {}

    loadEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            if (event) {
                this.eventEdit = event;
                let api_id;
                let app_id;
                if (event.notification_api_id) {
                    api_id = event.notification_api_id;
                } else {
                    api_id = environment.platform.oneSignalApiId;
                }

                if (event.notification_app_id) {
                    app_id = event.notification_app_id;
                } else {
                    app_id = environment.platform.oneSignalAppId;
                }

                this.formValidation.patchValue({
                    title: event.title,
                    shortcode: event.shortcode,
                    startDate: this.dt.getISODate(event.startDate),
                    endDate: this.dt.getISODate(event.endDate),
                    visibility: event.visibility,
                    allowChatUse: event.allow_chat,
                    allowVisioUse: event.allow_visio
                        ? event.allow_visio
                        : false,
                    allowAppointments: event.allow_appointments
                        ? event.allow_appointments
                        : false,
                    userLimit: event.userLimit,
                    gallery_file_limit: event.gallery_file_limit,
                    documents_file_limit: event.documents_file_limit,
                    notification_api_id: api_id,
                    notification_app_id: app_id,
                });

                const available = event.availableModules;
                this.modulesAvailable = [
                    {
                        name: this.nameModuleAttendee,
                        type: TypeModule.ATTENDEE,
                        selected: available.module_attendees,
                    },
                    {
                        name: this.nameModuleSpeaker,
                        type: TypeModule.SPEAKER,
                        selected: available.module_speaker,
                    },
                    {
                        name: this.nameModuleSponsor,
                        type: TypeModule.SPONSOR,
                        selected: available.module_sponsor,
                    },
                    {
                        name: this.nameModuleStand,
                        type: TypeModule.STAND,
                        selected: available.module_stand,
                    },
                    {
                        name: this.nameModuleSchedule,
                        type: TypeModule.SCHEDULE,
                        selected: available.module_schedule,
                    },
                    {
                        name: this.nameModuleWidget,
                        type: TypeModule.WIDGETS,
                        selected: available.module_widget,
                    },
                    {
                        name: this.nameModuleDocument,
                        type: TypeModule.DOCUMENT,
                        selected: available.module_document,
                    },
                    {
                        name: this.nameModuleGallery,
                        type: TypeModule.GALLERY,
                        selected: available.module_gallery,
                    },
                    {
                        name: this.nameModuleNewsFeed,
                        type: TypeModule.NEWS_FEED,
                        selected: available.module_feed_news,
                    },
                    {
                        name: this.nameModuleInfobooth,
                        type: TypeModule.INFOBOOTH,
                        selected: available.module_infobooth,
                    },
                    {
                        name: this.nameModuleCustomPage,
                        type: TypeModule.CUSTOM_PAGE,
                        selected: available.module_custom_page,
                    },
                    {
                        name: this.nameModuleCheckin,
                        type: TypeModule.CHECKIN,
                        selected: available.module_checkin,
                    },
                    {
                        name: this.nameModuleGamification,
                        type: TypeModule.GAMING,
                        selected: available.module_gamification,
                    },
                    {
                        name: this.nameModuleRanking,
                        type: TypeModule.RANKING,
                        selected: available.module_ranking,
                    },
                    {
                        name: this.nameModuleMap,
                        type: TypeModule.MAPS,
                        selected: available.module_maps,
                    },
                    {
                        name: this.nameModuleSurvey,
                        type: TypeModule.SURVEY,
                        selected: available.module_survey,
                    },
                    {
                        name: this.nameModuleQuiz,
                        type: TypeModule.QUIZ,
                        selected: available.module_quiz,
                    },
                    {
                        name: this.nameModuleAskQuestion,
                        type: TypeModule.ASK_QUESTION,
                        selected: available.module_ask_question,
                    },
                    {
                        name: this.nameModuleSessionFeedBack,
                        type: TypeModule.SESSION_FEEDBACK,
                        selected: available.module_session_feedback,
                    },
                    {
                        name: this.nameModuleGroupDiscussion,
                        type: TypeModule.NETWORKING,
                        selected: available.module_group_discussion,
                    },
                    {
                        name: this.nameModuleAppointments,
                        type: TypeModule.APPOINTMENTS,
                        selected: available.module_appointments,
                    },
                    {
                        name: this.nameModuleLeads,
                        type: TypeModule.LEADS,
                        selected: available.module_leads
                    },
                    {
                        name: this.nameModuleRooms,
                        type: TypeModule.ROOMS,
                        selected: available.module_rooms,
                    },
                    {
                        name: this.nameModuleWordCloud,
                        type: TypeModule.WORDCLOUD,
                        selected: available.module_word_cloud,
                    }
                ];

                // count the number of modules available
                for (const module of this.modulesAvailable) {
                    if (module.visOnlyForLoggedUser === undefined) {
                        module.visOnlyForLoggedUser = false;
                    }
                    if (module.visOnlyForUnloggedUser === undefined) {
                        module.visOnlyForUnloggedUser = false;
                    }
                    if (module.selected) {
                        this.qtdModels++;
                    }
                }
            }
        });
    }

    loadModules() {
        this.dbModule.getModules(this.eventId, (modules) => {
            this.modules = modules;
        });
    }

    updateEvent() {
        this.loader = true;
        let validation = true;
        this.dateError = false;
        this.startDatePastError = false;
        this.endDatePastError = false;
        this.updateEventError = false;

        const auxSDate: Array<string> = this.formValidation.value.startDate.split('-');    // [year, month, day]
        const startDate: number = this.dt.makeDateTp(auxSDate[0], auxSDate[1], auxSDate[2]);

        const auxEDate: Array<string> = this.formValidation.value.endDate.split('-');    // [year, month, day]
        const endDate: number = this.dt.makeDateTp(auxEDate[0], auxEDate[1], auxEDate[2]);

        const auxCDate: Array<string> = new Date().toISOString().split('T')[0].split("-");    // [year, month, day]
        const currentDate: number = this.dt.makeDateTp(auxCDate[0], auxCDate[1], auxCDate[2]);

        // start date after closure date
        if (startDate > endDate) {
            validation = false;
            this.dateError = true;
            this.loader = false;
        }

        // If the end date is in the past
        if (startDate < currentDate) {
            validation = false;
            this.startDatePastError = true;
            this.loader = false;
        }

        // If the end date is in the past
        if (endDate < currentDate) {
            validation = false;
            this.endDatePastError = true;
            this.loader = false;
        }

        if (validation) {
            // get datas
            this.eventEdit.title = this.formValidation.value.title;
            this.eventEdit.visibility = this.formValidation.value.visibility;
            this.eventEdit.userLimit = +this.formValidation.value.userLimit;
            this.eventEdit.startDate = this.dt.dbTime(startDate);
            this.eventEdit.endDate = this.dt.dbTime(endDate);
            this.eventEdit.gallery_file_limit = this.formValidation.value.gallery_file_limit;
            this.eventEdit.documents_file_limit = this.formValidation.value.documents_file_limit;
            this.eventEdit.allow_chat = this.formValidation.value.allowChatUse;
            this.eventEdit.allow_visio = this.formValidation.value.allowVisioUse;
            this.eventEdit.allow_appointments = this.formValidation.value.allowAppointments;
            this.eventEdit.notification_api_id = this.formValidation.value.notification_api_id;
            this.eventEdit.notification_app_id = this.formValidation.value.notification_app_id;

            this.eventEdit.availableModules.module_appointments = this.eventEdit.allow_appointments;

            // get available mdules
            for (const module of this.modulesAvailable) {
                if (module.type === TypeModule.ATTENDEE) {
                    this.eventEdit.availableModules.module_attendees = !!module.selected;
                } else if (module.type === TypeModule.SPEAKER) {
                    this.eventEdit.availableModules.module_speaker = !!module.selected;
                } else if (module.type === TypeModule.SPONSOR) {
                    this.eventEdit.availableModules.module_sponsor = !!module.selected;
                } else if (module.type === TypeModule.STAND) {
                    this.eventEdit.availableModules.module_stand = !!module.selected;
                } else if (module.type === TypeModule.WORDCLOUD) {
                    this.eventEdit.availableModules.module_word_cloud = !!module.selected;
                } else if (module.type === TypeModule.SCHEDULE) {
                    this.eventEdit.availableModules.module_schedule = !!module.selected;
                } else if (module.type === TypeModule.WIDGETS) {
                    this.eventEdit.availableModules.module_widget = !!module.selected;
                } else if (module.type === TypeModule.DOCUMENT) {
                    this.eventEdit.availableModules.module_document = !!module.selected;
                } else if (module.type === TypeModule.GALLERY) {
                    this.eventEdit.availableModules.module_gallery = !!module.selected;
                } else if (module.type === TypeModule.SURVEY) {
                    this.eventEdit.availableModules.module_survey = !!module.selected;
                } else if (module.type === TypeModule.QUIZ) {
                    this.eventEdit.availableModules.module_quiz = !!module.selected;
                } else if (module.type === TypeModule.ASK_QUESTION) {
                    this.eventEdit.availableModules.module_ask_question = !!module.selected;
                } else if (module.type === TypeModule.SESSION_FEEDBACK) {
                    this.eventEdit.availableModules.module_session_feedback = !!module.selected;
                } else if (module.type === TypeModule.NEWS_FEED) {
                    this.eventEdit.availableModules.module_feed_news = !!module.selected;
                } else if (module.type === TypeModule.MAPS) {
                    this.eventEdit.availableModules.module_maps = !!module.selected;
                } else if (module.type === TypeModule.RANKING) {
                    this.eventEdit.availableModules.module_ranking = !!module.selected;
                } else if (module.type === TypeModule.CHECKIN) {
                    this.eventEdit.availableModules.module_checkin = !!module.selected;
                } else if (module.type === TypeModule.INFOBOOTH) {
                    this.eventEdit.availableModules.module_infobooth = !!module.selected;
                } else if (module.type === TypeModule.CUSTOM_PAGE) {
                    this.eventEdit.availableModules.module_custom_page = !!module.selected;
                } else if (module.type === TypeModule.GAMING) {
                    this.eventEdit.availableModules.module_gamification = !!module.selected;
                } else if (module.type === TypeModule.NETWORKING) {
                    this.eventEdit.availableModules.module_group_discussion = !!module.selected;
                } else if (module.type === TypeModule.LEADS) {
                    this.eventEdit.availableModules.module_leads = !!module.selected;
                } else if (module.type === TypeModule.ROOMS) {
                    this.eventEdit.availableModules.module_rooms = !!module.selected;
                }
            }

            this.checkModulesBeforeUpdate().then(() => {
                this.dbEvent.updateEvent(this.eventEdit, (data) => {
                    if (data) {
                        this.successSwal.fire();
                        this.loader = false;
                    } else {
                        this.loader = false;
                        this.updateEventError = true;
                    }
                });
            }).catch(e => {
                this.loader = false;
                this.updateEventError = true;
                console.error(e)
            });
        }
    }

    async checkModulesBeforeUpdate() {
        // Personal calendar should only be created if there is a schedule module
        if (this.eventEdit.availableModules.module_schedule) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.SCHEDULE
            );
            if (checkModuleExist === -1) {
                this.moduleSchedule.order = this.modules.length;
                this.moduleSchedule.eventId = this.eventId;
                this.moduleSchedule.selected = this.eventEdit.availableModules.module_schedule;
                this.modules.push(this.moduleSchedule);
                let checkModuleExist2 = this.checkTypeModule(
                    TypeModule.PERSONALSCHEDULE
                );
                if (checkModuleExist2 === -1) {
                    this.modulePersonalAgenda.order =
                        this.moduleSchedule.order + 1;
                    this.modulePersonalAgenda.eventId = this.eventId;
                    this.modules.push(this.modulePersonalAgenda);
                }
            }
        }

        // Interactivity module should only be created if have at least 1 interacvity module
        if (
            this.eventEdit.availableModules.module_survey ||
            this.eventEdit.availableModules.module_quiz ||
            this.eventEdit.availableModules.module_session_feedback ||
            this.eventEdit.availableModules.module_ask_question
        ) {
            // create interactivity module
            let checkModuleExist = this.checkTypeModule(
                TypeModule.INTERACTIVITY
            );
            if (checkModuleExist === -1) {
                this.moduleInteractivity.order = this.modules.length;
                this.moduleInteractivity.eventId = this.eventId;
                this.modules.push(this.moduleInteractivity);
            }
        }

        if (this.eventEdit.availableModules.module_checkin) {
            let checkModuleExist = this.checkTypeModule(TypeModule.CHECKIN);
            if (checkModuleExist === -1) {
                this.moduleCheckin.order = this.modules.length;
                this.moduleCheckin.eventId = this.eventId;
                this.moduleSelfCheckin.order = this.moduleCheckin.order + 1;
                this.moduleSelfCheckin.eventId = this.eventId;
                this.modules.push(this.moduleCheckin);
                this.modules.push(this.moduleSelfCheckin);
            }
        }

        if (this.eventEdit.availableModules.module_attendees) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.ATTENDEE
            );
            if (checkModuleExist === -1) {
                this.moduleAttendee.order = this.modules.length;
                this.moduleAttendee.eventId = this.eventId;
                this.moduleAttendee.selected = this.eventEdit.availableModules.module_attendees;
                this.moduleAttendee["fields"] = Object.assign(
                    {},
                    new AttendeeFields()
                );
                this.modules.push(this.moduleAttendee);
            }
        }

        if (this.eventEdit.availableModules.module_speaker) {
            let checkModuleExist = this.checkTypeModule(TypeModule.SPEAKER);
            if (checkModuleExist === -1) {
                this.moduleSpeaker.order = this.modules.length;
                this.moduleSpeaker.eventId = this.eventId;
                this.moduleSpeaker.selected = this.eventEdit.availableModules.module_speaker;
                this.modules.push(this.moduleSpeaker);
            }
        }

        if (this.eventEdit.availableModules.module_sponsor) {
            let checkModuleExist = this.checkTypeModule(TypeModule.SPONSOR);
            if (checkModuleExist === -1) {
                this.moduleSponsor.order = this.modules.length;
                this.moduleSponsor.eventId = this.eventId;
                this.moduleSponsor.selected = this.eventEdit.availableModules.module_sponsor;
                this.modules.push(this.moduleSponsor);
            }
        } else {
            const checkModuleExist = this.checkTypeModule(TypeModule.SPONSOR);

            if (checkModuleExist > -1) {
                await this.dbModule.removeModule(
                    this.modules[checkModuleExist]
                );
                this.modules.splice(checkModuleExist, 1);
            }
        }

        if (this.eventEdit.availableModules.module_stand) {
            let checkModuleExist = this.checkTypeModule(TypeModule.STAND);
            if (checkModuleExist === -1) {
                this.moduleStand.order = this.modules.length;
                this.moduleStand.eventId = this.eventId;
                this.moduleStand.selected = this.eventEdit.availableModules.module_stand;
                this.modules.push(this.moduleStand);
            }
        } else {
            const checkModuleExist = this.checkTypeModule(TypeModule.STAND);

            if (checkModuleExist > -1) {
                await this.dbModule.removeModule(
                    this.modules[checkModuleExist]
                );
                this.modules.splice(checkModuleExist, 1);
            }
        }

        if (this.eventEdit.availableModules.module_ask_question) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.ASK_QUESTION
            );
            if (checkModuleExist === -1) {
                this.moduleAskQuestion.order = this.modules.length;
                this.moduleAskQuestion.eventId = this.eventId;
                this.moduleAskQuestion.selected = this.eventEdit.availableModules.module_ask_question;
                this.modules.push(this.moduleAskQuestion);
            }
        }

        if (this.eventEdit.availableModules.module_survey) {
            let checkModuleExist = this.checkTypeModule(TypeModule.SURVEY);
            if (checkModuleExist === -1) {
                this.moduleSurvey.order = this.modules.length;
                this.moduleSurvey.eventId = this.eventId;
                this.moduleSurvey.selected = this.eventEdit.availableModules.module_survey;
                this.modules.push(this.moduleSurvey);
            }
        }

        if (this.eventEdit.availableModules.module_session_feedback) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.SESSION_FEEDBACK
            );
            if (checkModuleExist === -1) {
                this.moduleSessionFeedback.order = this.modules.length;
                this.moduleSessionFeedback.eventId = this.eventId;
                this.moduleSessionFeedback.selected = this.eventEdit.availableModules.module_session_feedback;
                this.modules.push(this.moduleSessionFeedback);
            }
        }

        if (this.eventEdit.availableModules.module_quiz) {
            let checkModuleExist = this.checkTypeModule(TypeModule.QUIZ);
            if (checkModuleExist === -1) {
                this.moduleQuiz.order = this.modules.length;
                this.moduleQuiz.eventId = this.eventId;
                this.moduleQuiz.selected = this.eventEdit.availableModules.module_quiz;
                this.modules.push(this.moduleQuiz);
            }
        }

        if (this.eventEdit.availableModules.module_word_cloud) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.WORDCLOUD
            );
            if (checkModuleExist === -1) {
                this.moduleWordCloud.order = this.modules.length;
                this.moduleWordCloud.eventId = this.eventId;
                this.moduleDocuments.selected = this.eventEdit.availableModules.module_document;
                this.modules.push(this.moduleWordCloud);
            }
        }

        if (this.eventEdit.availableModules.module_infobooth) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.INFOBOOTH
            );
            if (checkModuleExist === -1) {
                this.moduleInfobooth.order = this.modules.length;
                this.moduleInfobooth.eventId = this.eventId;
                this.moduleInfobooth.selected = this.eventEdit.availableModules.module_infobooth;
                this.modules.push(this.moduleInfobooth);
            }
        }

        if (this.eventEdit.availableModules.module_custom_page) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.CUSTOM_PAGE
            );
            if (checkModuleExist === -1) {
                this.moduleCustomPage.order = this.modules.length;
                this.moduleCustomPage.eventId = this.eventId;
                this.moduleCustomPage.selected = this.eventEdit.availableModules.module_custom_page;
                this.modules.push(this.moduleCustomPage);
            }
        }

        if (this.eventEdit.availableModules.module_maps) {
            let checkModuleExist = this.checkTypeModule(TypeModule.MAPS);
            if (checkModuleExist === -1) {
                this.moduleMaps.order = this.modules.length;
                this.moduleMaps.eventId = this.eventId;
                this.modules.push(this.moduleMaps);
            }
        }

        if (this.eventEdit.availableModules.module_gamification) {
            let checkModuleExist = this.checkTypeModule(TypeModule.GAMING);
            if (checkModuleExist === -1) {
                this.moduleGamification.order = this.modules.length;
                this.moduleGamification.eventId = this.eventId;
                this.moduleGamification.selected = this.eventEdit.availableModules.module_gamification;
                this.modules.push(this.moduleGamification);
            }
        }

        if (this.eventEdit.availableModules.module_feed_news) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.NEWS_FEED
            );
            if (checkModuleExist === -1) {
                this.moduleFeedNews.order = this.modules.length;
                this.moduleFeedNews.eventId = this.eventId;
                this.moduleFeedNews.selected = this.eventEdit.availableModules.module_feed_news;
                this.modules.push(this.moduleFeedNews);
            }
        }

        if (this.eventEdit.availableModules.module_ranking) {
            let checkModuleExist = this.checkTypeModule(TypeModule.RANKING);
            if (checkModuleExist === -1) {
                this.moduleRanking.order = this.modules.length;
                this.moduleRanking.eventId = this.eventId;
                this.moduleRanking.selected = this.eventEdit.availableModules.module_ranking;
                this.modules.push(this.moduleRanking);
            }
        }

        if (this.eventEdit.availableModules.module_widget) {
            let checkModuleExist = this.checkTypeModule(TypeModule.WIDGETS);
            if (checkModuleExist === -1) {
                this.moduleWidget.order = this.modules.length;
                this.moduleWidget.eventId = this.eventId;
                this.moduleWidget.selected = this.eventEdit.availableModules.module_widget;
                this.modules.push(this.moduleWidget);
            }
        }

        if (this.eventEdit.availableModules.module_gallery) {
            let checkModuleExist = this.checkTypeModule(TypeModule.GALLERY);
            if (checkModuleExist === -1) {
                this.moduleGallery.order = this.modules.length;
                this.moduleGallery.eventId = this.eventId;
                this.moduleGallery.selected = this.eventEdit.availableModules.module_gallery;
                this.modules.push(this.moduleGallery);
            }
        }

        if (this.eventEdit.availableModules.module_document) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.DOCUMENT
            );
            if (checkModuleExist === -1) {
                this.moduleDocuments.order = this.modules.length;
                this.moduleDocuments.eventId = this.eventId;
                this.moduleDocuments.selected = this.eventEdit.availableModules.module_document;
                this.modules.push(this.moduleDocuments);
            }
        }

        if (this.eventEdit.availableModules.module_group_discussion) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.NETWORKING
            );

            if (checkModuleExist === -1) {
                this.moduleGroupDiscussion.order = this.modules.length;
                this.moduleGroupDiscussion.eventId = this.eventId;
                this.moduleGroupDiscussion.selected = this.eventEdit.availableModules.module_group_discussion;

                this.modules.push(this.moduleGroupDiscussion);
            }
        }

        if (this.eventEdit.availableModules.module_appointments) {
            let checkModuleExist = this.checkTypeModule(
                TypeModule.APPOINTMENTS
            );

            if (checkModuleExist === -1) {
                this.moduleAppointments.order = this.modules.length;
                this.moduleAppointments.eventId = this.eventId;
                this.moduleAppointments.selected = this.eventEdit.availableModules.module_appointments;

                this.modules.push(this.moduleAppointments);
            }
        } else {
            const checkModuleExist = this.checkTypeModule(
                TypeModule.APPOINTMENTS
            );

            if (checkModuleExist > -1) {
                await this.dbModule.removeModule(
                    this.modules[checkModuleExist]
                );
                await this.dbAppointments.removeModuleData(this.eventId);
                this.modules.splice(checkModuleExist, 1);
            }
        }

        if (this.eventEdit.availableModules.module_leads) {
            const modIndex = this.checkTypeModule(TypeModule.LEADS);
            if (modIndex == -1) {
                this.moduleLeads.order = this.modules.length;
                this.moduleLeads.eventId = this.eventId;
                this.moduleLeads.selected = true;
                this.modules.push(this.moduleLeads);
            }
        } else {
            const modIndex = this.checkTypeModule(TypeModule.LEADS);
            if (modIndex > -1) {
                await this.dbModule.removeModule(this.modules[modIndex]);
                this.modules.splice(modIndex, 1);
            }
        }

        if (this.eventEdit.availableModules.module_rooms) {
            let checkModuleExist = this.checkTypeModule(TypeModule.ROOMS);
            if (checkModuleExist === -1) {
                this.moduleRooms.order = this.modules.length;
                this.moduleRooms.eventId = this.eventId;
                this.moduleRooms.selected = this.eventEdit.availableModules.module_rooms;
                this.modules.push(this.moduleRooms);
            }
        } else {
            const checkModuleExist = this.checkTypeModule(TypeModule.ROOMS);

            if (checkModuleExist > -1) {
                await this.dbModule.removeModule(
                    this.modules[checkModuleExist]
                );
                this.modules.splice(checkModuleExist, 1);
            }
        }

        return this.dbEvent.updateEventModules(this.eventId, this.modules);
    }

    /**
     * @returns the index of the first match of a module with the same type as
     * the param "type", and if no match if found, return -1
     */
    checkTypeModule(type) {
        return this.modules.reduce((acc, module, index) => {
            return (module.type == type) ? index : acc;
        }, -1);
    }

    redirectList() {
        this.router.navigate(["/dashboard/events-b3"]);
    }

    addModule(module) {
        module.selected = true;
        for (let item of this.modulesAvailable) {
            if (module.type == item.type) item.selected = module.selected;
        }
        this.qtdModels++;
    }

    removeModule(module) {
        module.selected = false;
        for (let item of this.modulesAvailable) {
            if (module.type == item.type) item.selected = module.selected;
        }
        this.qtdModels--;
    }

    changeApiId() {
        if (this.allowChangeApiId) {
            this.allowChangeApiId = false;
        } else {
            this.allowChangeApiId = true;
        }
    }

    changeAppIdOneSignal() {
        if (this.allowChangeAppIdOneSignal) {
            this.allowChangeAppIdOneSignal = false;
        } else {
            this.allowChangeAppIdOneSignal = true;
        }
    }

    async changeAppointmentsAllowed() {
        if (this.eventEdit.allow_appointments) {
            const res = await this.deleteSwal.fire();
            if (!res.value) {
                this.eventEdit.allow_appointments = true;
                this.formValidation.controls["allowAppointments"].setValue(
                    true
                );
            } else {
                this.eventEdit.allow_appointments = false;
            }
        }
    }

    getUrlWithShortcode(): string {
        return `${this.defaultAppUrl}/#/${this.formValidation.value.shortcode}`;
    }
}
