import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleLeads extends Module {
    typeVision: number;
    selected: boolean;
    searchOn = {
        users: true,
        sponsors: false,
        stands: false
    }
    searchPaths;

    constructor(
        name: NameModule, 
        icon: string, 
        typeVision: number, 
        eventId: string, 
        order: number
    ) {
        super(name, icon, TypeModule.LEADS, eventId, order, true, true, true)
        this.typeVision = typeVision;
        this.selected = false;
        this.searchPaths = [
            `events/${eventId}/attendees`,
            `events/${eventId}/speakers`
        ]
    }
}
