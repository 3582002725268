import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, IterableDiffers } from '@angular/core';
import { WidgetIcons, icons } from '../../../../models/widget-icons.model';
import { Widget } from '../../../../models/widget.model';
import { StorageService } from '../../../../providers/storage/storage.service';
import { ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbWidgetsProvider } from 'src/app/providers/database/db-widgets';
import { UUID } from 'angular2-uuid';
import { DragulaService } from 'ng2-dragula';
import { DragulaOptions } from 'dragula';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DbSurveyProvider } from 'src/app/providers/database/db-survey';
import { TypeModule } from 'src/app/enums/type-module';
import { DbQuizProvider } from 'src/app/providers/database/db-quiz';
import { DbTrainingProvider } from 'src/app/providers/database/db-training';
import { DbAskQuestionProvider } from 'src/app/providers/database/db-ask-question';
import { TypeVisionModule } from 'src/app/enums/type-vision-module';
import { Group } from 'src/app/models/group';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { Languages } from 'src/app/models/languages';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import { ModuleWidget } from 'src/app/models/modules/module-widget';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NameModule } from 'src/app/models/name-module';
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'app-widgets',
    templateUrl: './widgets.component.html',
    styleUrls: ['./widgets.component.scss'],
    encapsulation: ViewEncapsulation.None
})

/*
 This module was one of the first to be developed, so it has a little
 messy code. The code has never been improved, so it can be a little
 confusing
*/

export class WidgetsComponent implements OnInit {
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorEditWidgetSwal') public errorEditWidgetSwal: SwalComponent;
    @ViewChild('errorDeleteWidgetSwal') public errorDeleteWidgetSwal: SwalComponent;
    @ViewChild('errorWidgetPrincipalLanguageBlank') public errorWidgetPrincipalLanguageBlank: SwalComponent;

    // start dragula options
    dragulaOptions: DragulaOptions = {
        moves: () => true,
        accepts: () => {
            if (this.blockNewWidget == true) {
                this.errorEditWidgetSwal.fire()
                return false;
            }
            return true
        }

    }
    dragulaOptionsDesktop: DragulaOptions = {
        moves: () => true,
        accepts: () => {
            if (this.blockNewWidget == true) {
                this.errorEditWidgetSwal.fire()
                return false;
            }
            return true
        }

    }
    eventMenuColor: string = null;
    eventMenuTxtColor: string = null;
    public moduleWidget: ModuleWidget = null;
    eventId: string = null;
    moduleId: string = null;
    baseImgUrl: string = '../../../../../assets/images/';

    orderChanged: boolean = false;

    // new widget
    enableStyle: boolean = false;
    enableBorder: boolean = false;
    enableBg: boolean = false;
    borderIcon: string = null;
    bgIcon: string = null;
    borderColor: string = '#F0F0F0';
    backgroundColor: string = '#F0F0F0';
    proportion: string = null;
    addBorder: boolean = false;
    addBg: boolean = false;
    widgetIsExtern: boolean = false;
    widgetRoute: string = '-1';
    widgetRouteLink: string = null;
    widgetExternUrl: string = '';
    subtitleTxtColor: string = '#ffffff';
    subtitleBgColor: string = '#000000';
    subtitleBgTransparency: boolean = false;
    subtitleTxtColorEdit: string = '#ffffff';
    subtitleBgColorEdit: string = '#000000';

    enableExternalInput: boolean = false;

    // display selected file name in input file
    file = {
        PtBR: null,
        EnUS: null,
        EsES: null,
        FrFR: null,
        DeDE: null
    };
    fileName: string = null;
    base64Picture: string = null;
    imageType: string = null;
    selectedIcon = {
        PtBR: null,
        EnUS: null,
        EsES: null,
        FrFR: null,
        DeDE: null
    };

    // texts for widget title
    widgetText_ptBR: string = null; // portuguese - Brazil
    widgetText_enUS: string = null; // english - USA
    widgetText_esES: string = null; // spanish - Spain
    widgetText_frFR: string = null; // france - France
    widgetText_deDE: string = null; // german - Germany

    // icons
    icons: WidgetIcons[] = icons;
    iconColor: Array<any> = [];

    // widgets array to display with ngFor
    widgets: Array<any> = [];

    // modules Redirect
    modulesRedirect: Array<any> = [];

    // block the addition of a new widget if the user has not finished adding the current widget
    blockNewWidget: boolean = false;
    // index of widget added
    lastWidget: number = null;
    // loader for slowness
    loader: boolean = false;
    loaderWidgets: boolean = false;
    loaderSaveOrder: boolean = false;
    // display edit view and block add new widget
    editView: boolean = false;

    // edit widget options
    widgetEdit_ptBR: string;
    widgetEdit_enUS: string;
    widgetEdit_esES: string;
    widgetEdit_frFR: string;
    widgetEdit_deDE: string;
    widgetEditImage = {
        PtBR: null,
        EnUS: null,
        EsES: null,
        FrFR: null,
        DeDE: null
    };
    // List of the dimensions to be displayed. E.g. 1024x500
    dimensions: Array<any>;
    widgetEditProportion: string;
    widgetEditIsImage: boolean;
    widgetEditBackgroundEnabled: boolean;
    widgetEditBorderEnabled: boolean;
    widgetEditBorderColor: string;
    widgetEditBackgroundColor: string;
    widgetEditOrder: number;
    widgetEditStorageId: string;
    widgetEditUid: string;
    widgetEditBorderSize: string;
    widgetEditIndex: number;
    widgetEditIsExternal: boolean;
    widgetEditIconFill: string;
    widgetEditTypeVision: number;
    WidgetEditVisForLoggedUsers: boolean;
    WidgetEditVisForUnloggedUsers: boolean;
    widgetEditGroups = []

    // errors
    proportionError: boolean = false;
    fileError: boolean = false;

    // delete widget
    widgetDeleteId: string = null;
    widgetDeleteIndex: number;
    widgetDeleteStorage: string = null;
    widgetDeleteIsIcon: boolean = false;
    activeProportion: string = null;
    event = null;
    userLang: string = null;
    iterableDiffer;
    widgetColor: string = null;

    imageSizeDefault: string = null;

    // type vision widget
    typeVision: number = TypeVisionModule.GLOBAL_VISION
    vision_global = TypeVisionModule.GLOBAL_VISION
    vision_group_access_permission = TypeVisionModule.GROUP_ACCESS_PERMISSION

    // GROUPS FILTER  CREATE
    selectedGroupCreate: Array<Group> = [];
    filteredListGroupsCreate = [];
    queryGroupCreate = '';

    // GROUPS FILTER EDIT
    filteredListGroupsEdit = [];
    queryGroupEdit = '';

    groups: Array<Group> = []


    // survey
    selectSurvey: boolean = false;
    listSurveys: Array<any> = [];
    loaderSurvey: boolean = false;
    moduleIdSurvey: string = null;

    // quiz
    selectQuiz: boolean = false;
    listQuiz: Array<any> = [];
    loaderQuiz: boolean = false;
    moduleIdQuiz: string = null;

    // training
    selectTraining: boolean = false;
    listTraining: Array<any> = [];
    loaderTraining: boolean = false;
    moduleIdTraining: string = null;

    // ask a question
    selectAskQuestion: boolean = false;
    listAskQuestions: Array<any> = [];
    loaderAskQuestion: boolean = false;
    moduleIdAskQuestion: string = null;
    languages: Languages = null //event languages
    principalEventLanguageFormated: string = 'PtBR';
    activeLanguageWidget: string = 'PtBR';
    public userLanguage: string
    nameFile: string = null;
    nameFileDesktop: string = null;
    displayImage = null;
    displayImageDesktop = null;

    open_link_behavior = 'in-app'
    mobileView = true;
    desktopWidgets = []
    mobileWidgets = []

    adding = false;
    imageIframe = ''
    imageIframeSrc = ''
    isIframe = false;
    isIframeEdit = false;
    widgetVisForLoggedUsers = false;
    widgetVisForUnloggedUsers = false;
    imageIframeEdit = ''
    imageIframeEditSrc = ''
    imageIframeSrcSanitized;
    imageIframeEditSrcSanitized;
    desktopIframes = []
    mobileIframes = []
    eventVisibility: any;

    successBgModuleUpdate: boolean = false;
    errorBgModuleUpdate: boolean = false;
    selectBgModuleRequired: boolean = false;
    loaderBgUpdate: boolean = false;

    addForBothScreen = false;
    globalGridArea; // to duplicate a widget

    isEmptyWidget: boolean = false;
    isEmptyWidgetEdit: boolean = false;

    widgetImgType = 'image';
    mobileModuleMenu: boolean = false;

    constructor(
        private storage: StorageService,
        private route: ActivatedRoute,
        private dbWidgets: DbWidgetsProvider,
        private dbEvents: DbEventsProvider,
        private dragula: DragulaService,
        private dbEvent: DbEventsProvider,
        private _iterableDiffers: IterableDiffers,
        private dbSurvey: DbSurveyProvider,
        private dbQuiz: DbQuizProvider,
        private dbTraining: DbTrainingProvider,
        private dbAskQuestion: DbAskQuestionProvider,
        private dbGroups: DbGroupsProvider,
        private global: GlobalService,
        public formatedLanguage: FormatedEventLanguageService,
        private sanitizer: DomSanitizer,
        private translateService: TranslateService,
        public http: HttpClient
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        dragula.createGroup('bag-widget-mobile', this.dragulaOptions);
        dragula.createGroup('bag-widget-desktop', this.dragulaOptionsDesktop);
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);

        let cont = 1;
        for (const icon of this.icons) {
            if (Math.ceil(cont / 3) % 2 == 1) {
                icon.position = 'right';
            } else {
                icon.position = 'left';
            }
            cont++;
        }

        this.dimensions = [
            {
                'text': '1024x500',
                'cls': 'col-12 col-1row',
                'src': './assets/images/widget-1.png'

            },
            {
                'text': '500x500',
                'cls': 'col-6 col-1row',
                'src': './assets/images/widget-2.png'

            },
            {
                'text': '500x1024',
                'cls': 'col-6 col-2row',
                'src': './assets/images/widget-3.png'

            },
            {
                'text': '1024x1024',
                'cls': 'col-12 col-2row',
                'src': './assets/images/widget-4.png'

            },
            {
                'text': '500x500',
                'cls': 'col-6 col-1row col-rounded',
                'src': './assets/images/widget-5.png'

            },
            {
                'text': '500x250',
                'cls': 'col-6 col-05row',
                'src': './assets/images/widget-6.png'

            },
            {
                'text': '1024x250',
                'cls': 'col-12 col-05row',
                'src': './assets/images/widget-7.png'

            },
            {
                'text': '2048x500',
                'cls': 'col-12 col-1row desktop',
                'src': './assets/images/widget-8.png'
            },
            {
                'text': '2048x250',
                'cls': 'col-12 col-05row desktop',
                'src': './assets/images/widget-9.png'
            }
        ];
    }

    isWidgetTypeDisabled = (proportion) => proportion && !proportion.includes('col-6 col-1row');

    changeIconFill(color: string) {
        this.widgets[this.lastWidget].iconFill = color;
    }

    changeIconFillEdit(color: string) {
        if(this.mobileView) this.mobileWidgets[this.widgetEditIndex].iconFill = color;
        else this.desktopWidgets[this.widgetEditIndex].iconFill = color;
        // this.widgets[this.widgetEditIndex].iconFill = color;
    }


    ngOnInit() {
        this.loadWidgets();
        this.loadEvent();
        this.loadModules();
        this.loadGroups();
        this.getUserLanguage()
        this.dragula.dropModel('bag-widget-mobile').subscribe((value: any) => {
            this.onReorder(value, 'mobile');
        });
        this.dragula.dropModel('bag-widget-desktop').subscribe((value: any) => {
            this.onReorder(value, 'desktop');
        });
    }

    ngDoCheck() { // verifica se houve mudança no parâmetro do idModule
        if (this.moduleId !== this.route.params['_value']['moduleId']) {
            this.moduleId = this.route.params['_value']['moduleId']

            this.loadWidgets();
            this.loadEvent();
            this.loadModules();
            this.getUserLanguage()
        }
    }

    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    loadEvent() {
        this.dbEvents.getEvent(this.eventId, (event) => {
            if (typeof event !== 'undefined' && event !== null) {
                this.event = event;
                this.languages = event.languages;
                this.eventVisibility = event.visibility;
                this.formatedLanguage.convertLangFormat(this.event.language);
                this.principalEventLanguageFormated = this.formatedLanguage.language
                this.activeLanguageWidget = this.principalEventLanguageFormated;
                this.eventMenuColor = this.event.colors.menu_color;
                this.eventMenuTxtColor = this.event.colors.menu_text_color;
                this.mobileModuleMenu = !!event.mobileModuleMenu;
            }
        });
    }

    loadWidgets() {
        this.loaderWidgets = true;
        this.dbWidgets.getWidgetByModule(this.moduleId, async (allWidgets: Array<Widget>) => {
            this.widgets = await allWidgets;
            this.desktopWidgets = [...this.widgets].filter(w => w.gridArea !== undefined && w.gridArea !== null)
            this.mobileWidgets = [...this.widgets].filter(w => w.gridArea == undefined || w.gridArea == null)
            this.desktopWidgets.forEach(w => {
                if(w.iframeSrc !== undefined && w.iframeSrc !== '')
                this.desktopIframes[w.uid] = this.sanitizer.bypassSecurityTrustResourceUrl(w.iframeSrc)
            })
            this.mobileWidgets.forEach(w => {
                if(w.iframeSrc !== undefined && w.iframeSrc !== '')
                this.mobileIframes[w.uid] = this.sanitizer.bypassSecurityTrustResourceUrl(w.iframeSrc)
            })
            this.loaderWidgets = false;
            this.loader = false;
        });
    }

    moduleBackgroundColor: string = '#F6F6F6';
    moduleBackgroundColorSecondary: string = '#F6F6F6';
    moduleBackgroundImage: string = '';
    moduleBackgroundImageDesktop: string = '';
    moduleBackgroundTypeImage: boolean = false;
    moduleBackgroundIsDegrade: boolean = false;

    loadModules() {
        this.dbEvent.getModulesEvent(this.eventId, (modules) => {
            this.modulesRedirect = [];
            for (const module of modules) {
                if (module.order !== null) {
                    if (module.uid == this.moduleId) {
                        this.moduleWidget = module;
                        this.moduleWidget.moduleBackgroundTypeImage = this.moduleWidget.moduleBackgroundTypeImage ? this.moduleWidget.moduleBackgroundTypeImage : this.moduleBackgroundTypeImage;
                        this.moduleWidget.moduleBackgroundIsDegrade = this.moduleWidget.moduleBackgroundIsDegrade ? this.moduleWidget.moduleBackgroundIsDegrade : this.moduleBackgroundIsDegrade;
                        this.moduleWidget.moduleBackgroundImage = this.moduleWidget.moduleBackgroundImage ? this.moduleWidget.moduleBackgroundImage : this.moduleBackgroundImage;
                        this.moduleWidget.moduleBackgroundImageDesktop = this.moduleWidget.moduleBackgroundImageDesktop ? this.moduleWidget.moduleBackgroundImageDesktop : this.moduleBackgroundImageDesktop;
                        this.moduleWidget.moduleBackgroundColor = this.moduleWidget.moduleBackgroundColor ? this.moduleWidget.moduleBackgroundColor : this.moduleBackgroundColor;
                        this.moduleWidget.moduleBackgroundColorSecondary = this.moduleWidget.moduleBackgroundColorSecondary ? this.moduleWidget.moduleBackgroundColorSecondary : this.moduleBackgroundColorSecondary;
                    }
                    this.modulesRedirect.push(module);
                }
            }
            const moduleChat = {
                uid: 'chat-history',
                viewApp: `/event/${this.eventId}/chat-history`,
                name: {
                    DeDE: 'Persönlicher Chat',
                    EnUS: 'Personal chat',
                    EsES: 'Chat personal',
                    FrFR: 'chat personnel',
                    PtBR: 'Bate-papo pessoal'
                }
            }
            const moduleNotification = {
                uid: 'notification',
                viewApp: `notification`,
                name: {
                    DeDE: 'Benachrichtigungen',
                    EnUS: 'Notifications',
                    EsES: 'Notificaciones',
                    FrFR: 'Push Notification',
                    PtBR: 'Notificações'
                }
            }
            this.modulesRedirect.push(moduleChat)
            this.modulesRedirect.push(moduleNotification)
            if(this.event.visibility === true){
                const modulePublicRegister= {
                    uid: 'public-register',
                    viewApp: `PublicRegister`,
                    name: {
                        DeDE: 'Registrieren',
                        EnUS: 'Register',
                        EsES: 'Registrarse',
                        FrFR: 'S\'enregistrer',
                        PtBR: 'Registro'
                    }
                }
                const modulePublicLogin= {
                    uid: 'public-login',
                    viewApp: `PublicLogin`,
                    name: {
                        DeDE: 'Anmeldung',
                        EnUS: 'Log in',
                        EsES: 'acceso',
                        FrFR: 'Connexion',
                        PtBR: 'Conecte-se'
                    }
                }
                this.modulesRedirect.push(modulePublicRegister)
                this.modulesRedirect.push(modulePublicLogin)
            }
        });
    }

    // get background of the module
    getBackground(type: string) {
        let style = {};
        style = { 'background-color': '#F6F6F6' }
        if (this.moduleWidget !== undefined && this.moduleWidget !== null) {
            const image = type === 'mobile' ? this.moduleWidget['moduleBackgroundImage'] : this.moduleWidget['moduleBackgroundImageDesktop']
            if (this.moduleWidget['moduleBackgroundTypeImage']) {
                style = {
                    'background-image': "url(\'" + image + "')",
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat'
                }
            } else {
                if (!this.moduleWidget['moduleBackgroundIsDegrade']) {
                    style = {
                        'background-color': this.moduleWidget['moduleBackgroundColor'],

                    }
                } else {
                    style = {
                        'background': "linear-gradient(to right, " + this.moduleWidget['moduleBackgroundColor'] + "," + this.moduleWidget['moduleBackgroundColorSecondary'] + ")",

                    }
                }
            }
        }
        return style;
    }

    // on reorder items (widgets), change the order property
    onReorder(order: any, type: string): void {
        this.orderChanged = true;
        console.log('order.targetModel', order.targetModel)
        if(type === 'mobile'){
            this.mobileWidgets = order.targetModel;
            for (let i = 0; i < (this.mobileWidgets).length; ++i) {
                this.mobileWidgets[i].order = i;
            }
        } else {
            this.desktopWidgets = order.targetModel;

            for (let i = 0; i < (this.desktopWidgets).length; ++i) {
                this.desktopWidgets[i].order = i;
            }
        }
        //this.widgets = [...this.mobileWidgets, ...this.desktopWidgets].sort((a,b) => a.order - b.order)
    }

    // save new order of items (widgets)
    saveNewOrder() {
        this.loaderSaveOrder = true;
        this.dbWidgets.changeOrderWidgets(this.widgets, (status) => {
            if (status == true) {
                // case success
                this.loaderSaveOrder = false;
                this.orderChanged = false;
                this.successSwal.fire();
                this.loadWidgets();
            } else {
                // case error
                this.loaderSaveOrder = false;
                this.errorSwal.fire();
            }
        })
    }

    iframeImgEditChanged(ev){
        const iframeStr = ev.target.value
        this.imageIframeEditSrc = iframeStr.includes('src="') ? iframeStr.split(' ').filter(x => x.indexOf('src') === 0)[0].split('"')[1] : ''
        this.imageIframeEditSrcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageIframeEditSrc)

    }

    // on edit widget, get item infos
    editWidget(widget, index) {
        this.widgetEditIndex = index;
        console.log('index edit =',this.widgetEditIndex)
        this.isEmptyWidgetEdit = widget.image[this.activeLanguageWidget] === 'assets/images/empty_widget.png'
        this.isIframeEdit = widget.iframeImg !== undefined && widget.iframeImg !== ''
        this.imageIframeEdit = this.isIframeEdit ? widget.iframeImg : ''
        this.imageIframeEditSrc = widget.iframeSrc ? widget.iframeSrc : ''
        this.imageIframeEditSrcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageIframeEditSrc)
        // console.log(this.imageIframeEdit, widget)
        this.widgetRouteLink = null;
        this.widgetExternUrl = '';
        this.blockNewWidget = true;
        this.editView = true;
        this.selectSurvey = false;
        this.selectTraining = false;
        this.selectAskQuestion = false;
        this.proportion = widget.proportion;
        if (widget.isImage == true) {
            this.enableStyle = false
        } else {
            this.enableStyle = true;
        }

        this.widgetEditOrder = widget.order;
        this.widgetEditIsImage = widget.isImage;
        this.widgetEditImage = {...widget.image};
        console.log(widget.image)
        for (const lang in this.widgetEditImage) {
            this.file[lang] = this.widgetEditImage[lang];
        }
        this.enableBorder = widget.borderEnabled;
        this.enableBg = widget.backgroundEnabled;
        this.borderIcon = widget.borderSize;
        this.widgetEditStorageId = widget.storageId;
        this.widgetIsExtern = widget.isExtern;
        this.subtitleBgColorEdit = widget.subtitleBgColor;
        this.subtitleTxtColorEdit = widget.subtitleTxtColor;
        this.borderColor = widget.borderColor;
        this.backgroundColor = widget.backgroundColor;
        this.subtitleTxtColorEdit = widget.subtitleTxtColor;
        this.subtitleBgColorEdit = widget.subtitleBgColor;
        this.activeProportion = widget.proportion;

        if (this.widgetIsExtern) {
            this.widgetRoute = 'externalPage';
            this.widgetExternUrl = widget.route;
            this.widgetRouteLink = widget.route;
            this.open_link_behavior = widget.open_link_behavior;
        } else {
            this.widgetRoute = widget.route;
            this.open_link_behavior = widget.open_link_behavior ? widget.open_link_behavior : 'in-app';
            if (this.widgetRoute.includes('surveys-list')) {
                this.getSurveys();
                this.selectSurvey = true;
                this.widgetRouteLink = '-1';
                // this.widgetRouteLink = this.widgetRoute;
                // this.widgetRoute = this.getModuleSubstring(this.widgetRouteLink, 'surveys-list');
            } else if (this.widgetRoute.includes('survey')) {
                this.getSurveys();
                this.selectSurvey = true;
                this.widgetRouteLink = this.widgetRoute;
                this.widgetRoute = this.getModuleSubstring(this.widgetRouteLink, 'survey');
            } else if (this.widgetRoute.includes('quiz-list')) {
                this.getQuiz();
                this.selectQuiz = true;
                this.widgetRouteLink = '-1';
                // this.widgetRouteLink = this.widgetRoute;
                // this.widgetRoute = this.getModuleSubstring(this.widgetRouteLink, 'quiz-list');
            } else if (this.widgetRoute.includes('quiz')) {
                this.getQuiz();
                this.selectQuiz = true;
                this.widgetRouteLink = this.widgetRoute;
                this.widgetRoute = this.getModuleSubstring(this.widgetRouteLink, 'quiz');
            } else if (this.widgetRoute.includes('ask-question-list')) {
                this.getAskQuestion();
                this.selectAskQuestion = true;
                this.widgetRouteLink = '-1';
                // this.widgetRouteLink = this.widgetRoute;
                // this.widgetRoute = this.getModuleSubstring(this.widgetRouteLink, 'ask-question-list');
            } else if (this.widgetRoute.includes('training-list')) {
                this.getTraining();
                this.selectTraining = true;
                this.widgetRouteLink = '-1';
                // this.widgetRouteLink = this.widgetRoute;
                // this.widgetRoute = this.getModuleSubstring(this.widgetRouteLink, 'training');
            } else if (this.widgetRoute.includes('training')) {
                this.getTraining();
                this.selectTraining = true;
                this.widgetRouteLink = this.widgetRoute;
                this.widgetRoute = this.getModuleSubstring(this.widgetRouteLink, 'training');
            } else if (this.widgetRoute.includes('ask-question')) {
                this.getAskQuestion();
                this.selectAskQuestion = true;
                this.widgetRouteLink = this.widgetRoute;
                this.widgetRoute = this.getModuleSubstring(this.widgetRouteLink, 'ask-question');
            }
        }

        this.widgetEdit_ptBR = widget.title.pt_BR;
        this.widgetEdit_enUS = widget.title.en_US;
        this.widgetEdit_esES = widget.title.es_ES;
        this.widgetEdit_frFR = widget.title.fr_FR;
        this.widgetEdit_deDE = widget.title.de_DE;
        this.widgetEditUid = widget.uid;
        this.widgetEditBackgroundColor = widget.backgroundColor;
        this.widgetEditBorderColor = widget.borderColor;
        this.widgetEditBackgroundEnabled = widget.backgroundEnable;
        this.widgetEditBorderEnabled = widget.borderEnabled;
        this.widgetEditProportion = widget.proportion;
        this.widgetEditIconFill = widget.iconFill;

        // gestion des informations de visisbilités
        this.WidgetEditVisForLoggedUsers = widget.widgetVisForLoggedUsers ? widget.widgetVisForLoggedUsers : false;
        this.WidgetEditVisForUnloggedUsers = widget.widgetVisForUnloggedUsers ? widget.widgetVisForUnloggedUsers : false;        

        this.widgetEditTypeVision = widget.typeVision;
        this.widgetEditGroups = [];
        // tslint:disable-next-line: forin
        for (const index in widget.groups) {
            const group = widget.groups[index]
            this.widgetEditGroups.push(group)
        }
    }

    // get module viewApp
    getModuleSubstring(str: string, path: string) {
        const myString = str.split(`${path}/`).pop();
        const moduleId = myString.substring(0, myString.indexOf("/"));
        const index = this.modulesRedirect.map(function (e) { return e.uid; }).indexOf(moduleId);
        return (this.modulesRedirect[index].viewApp);
    }


    // check widget edit (update widget)
    async saveWidgetEdit() {
        // start loader
        this.loader = true;

        // check the vision type and groups.
        const groups = {}
        if (this.widgetEditTypeVision === this.vision_group_access_permission) {

            for (const group of this.widgetEditGroups) {
                groups[group.uid] = group
            }
        }
        if(this.isIframeEdit){
            if(this.imageIframeEdit !== '' ){
                // const iframeSrc = this.imageIframeEdit.split(' ').filter(x => x.indexOf('src') === 0)[0].split('"')[1]

                const auxWidget = this.mobileView ? this.mobileWidgets[this.widgetEditIndex] : this.desktopWidgets[this.widgetEditIndex];
                const widget: Widget = new Widget(
                    this.proportion,
                     this.widgetEditOrder,
                      this.widgetEditIsImage,
                       this.widgetEditImage,
                        auxWidget.backgroundColor,
                         auxWidget.borderColor,
                          this.addBorder,
                           this.addBg,
                            this.borderIcon,
                             this.eventId,
                              this.moduleId,
                               this.widgetEditStorageId,
                                this.widgetRoute,
                                 this.widgetIsExtern,
                                  this.widgetEditTypeVision,
                                   groups,
                                    this.WidgetEditVisForLoggedUsers,
                                    this.WidgetEditVisForUnloggedUsers,
                                     this.open_link_behavior);
                widget.uid = this.widgetEditUid;
                widget.title = {
                    pt_BR: this.widgetEdit_ptBR,
                    en_US: this.widgetEdit_enUS,
                    es_ES: this.widgetEdit_esES,
                    fr_FR: this.widgetEdit_frFR,
                    de_DE: this.widgetEdit_deDE
                };
                widget.subtitleBgColor = this.subtitleBgColorEdit;
                widget.subtitleTxtColor = this.subtitleTxtColorEdit;
                widget.open_link_behavior = this.open_link_behavior;
                if (auxWidget.iconFill !== undefined) widget.iconFill = auxWidget.iconFill;
                widget.iframeImg = this.imageIframeEdit !== undefined && this.imageIframeEdit !== '' ? this.imageIframeEdit : '';
                widget.iframeSrc = this.imageIframeEdit !== '' ? this.imageIframeEditSrc : '';
                widget.image['DeDE'] = null
                widget.image['EnUS'] = null
                widget.image['FrFR'] = null
                widget.image['PtBR'] = null
                widget.image['EsES'] = null
                this.updateWidgetDb(widget);
                return ;
            } else {
                console.log('someting wrong')
            }
        }
        // if (this.widgetIsExtern) {
        //   this.widgetRoute = this.widgetRouteLink;
        // }

        if (this.widgetRouteLink == null || this.widgetRouteLink == '-1') {
            this.widgetRouteLink = '-1';
        }

        if (this.widgetIsExtern == true) {
            this.widgetRoute = this.widgetExternUrl;
        } else if
            (this.widgetRouteLink.includes('survey') ||
            this.widgetRouteLink.includes('quiz') ||
            this.widgetRouteLink.includes('ask-question')
        ) {
            this.widgetRoute = this.widgetRouteLink;
        }
        const haveFile = this.isEmpty(this.file);
        const haveIcon = this.isEmpty(this.selectedIcon);
        if (!haveFile && !haveIcon) {
            // let auxWidget = this.widgets[this.widgetEditIndex];
            const auxWidget = this.mobileView ? this.mobileWidgets[this.widgetEditIndex] : this.desktopWidgets[this.widgetEditIndex];
            const widget: Widget = new Widget(
                this.proportion,
                 this.widgetEditOrder,
                  this.widgetEditIsImage,
                   this.widgetEditImage,
                    auxWidget.backgroundColor,
                     auxWidget.borderColor,
                      this.addBorder,
                       this.addBg,
                        this.borderIcon,
                         this.eventId,
                          this.moduleId,
                           this.widgetEditStorageId,
                            this.widgetRoute,
                             this.widgetIsExtern,
                              this.widgetEditTypeVision,
                               groups,
                                this.WidgetEditVisForLoggedUsers,
                                this.WidgetEditVisForUnloggedUsers,
                                 this.open_link_behavior);
            widget.uid = this.widgetEditUid;
            widget.title = {
                pt_BR: this.widgetEdit_ptBR,
                en_US: this.widgetEdit_enUS,
                es_ES: this.widgetEdit_esES,
                fr_FR: this.widgetEdit_frFR,
                de_DE: this.widgetEdit_deDE
            };
            widget.subtitleBgColor = this.subtitleBgColorEdit;
            widget.subtitleTxtColor = this.subtitleTxtColorEdit;
            widget.open_link_behavior = this.open_link_behavior;
            if (auxWidget.iconFill !== undefined) widget.iconFill = auxWidget.iconFill;
            this.updateWidgetDb(widget);
        } else {
            // verify style (IMAGE or ICON)
            if (this.enableStyle == true) {
                // let auxWidget = this.widgets[this.widgetEditIndex];
                const auxWidget = this.mobileView ? this.mobileWidgets[this.widgetEditIndex] : this.desktopWidgets[this.widgetEditIndex];

                const widget: Widget = new Widget(
                    this.proportion,
                     this.widgetEditOrder,
                      false,
                       this.selectedIcon,
                        auxWidget.backgroundColor,
                         auxWidget.borderColor,
                          this.addBorder,
                           this.addBg,
                            this.borderIcon,
                             this.eventId,
                              this.moduleId,
                               null,
                                this.widgetRoute,
                                 this.widgetIsExtern,
                                  this.widgetEditTypeVision,
                                   groups,
                                    this.WidgetEditVisForLoggedUsers,
                                    this.WidgetEditVisForUnloggedUsers,
                                     this.open_link_behavior);
                widget.uid = this.widgetEditUid;
                widget.title = {
                    pt_BR: this.widgetEdit_ptBR,
                    en_US: this.widgetEdit_enUS,
                    es_ES: this.widgetEdit_esES,
                    fr_FR: this.widgetEdit_frFR,
                    de_DE: this.widgetEdit_deDE
                };
                widget.subtitleBgColor = this.subtitleBgColorEdit;
                widget.subtitleTxtColor = this.subtitleTxtColorEdit;
                if (auxWidget.iconFill !== undefined) widget.iconFill = auxWidget.iconFill;
                this.updateWidgetDb(widget);
            } else {
                // case style is IMAGE, upload to Firebase Storage and get URL
                const storageId = UUID.UUID();
                // let auxWidget = this.widgets[this.widgetEditIndex];
                const auxWidget = this.mobileView ? this.mobileWidgets[this.widgetEditIndex] : this.desktopWidgets[this.widgetEditIndex];

                const widget: Widget = new Widget(
                    this.proportion,
                    this.widgetEditOrder,
                    true,
                    this.widgetEditImage,
                    auxWidget.backgroundColor,
                    auxWidget.borderColor,
                    this.addBorder,
                    this.addBg,
                    this.borderIcon,
                    this.eventId,
                    this.moduleId,
                    storageId,
                    this.widgetRoute,
                    this.widgetIsExtern,
                    this.widgetEditTypeVision,
                    groups,
                    this.WidgetEditVisForLoggedUsers,
                    this.WidgetEditVisForUnloggedUsers,
                    this.open_link_behavior);
                widget.uid = this.widgetEditUid;
                widget.title = {
                    pt_BR: this.widgetEdit_ptBR,
                    en_US: this.widgetEdit_enUS,
                    es_ES: this.widgetEdit_esES,
                    fr_FR: this.widgetEdit_frFR,
                    de_DE: this.widgetEdit_deDE
                };
                widget.subtitleBgColor = this.subtitleBgColorEdit;
                widget.subtitleTxtColor = this.subtitleTxtColorEdit;
                if (auxWidget.iconFill !== undefined) widget.iconFill = auxWidget.iconFill;

                if(this.isEmptyWidgetEdit){
                    widget.image = {
                        PtBR: 'assets/images/empty_widget.png',
                        EnUS: 'assets/images/empty_widget.png',
                        EsES: 'assets/images/empty_widget.png',
                        FrFR: 'assets/images/empty_widget.png',
                        DeDE: 'assets/images/empty_widget.png'
                    };
                    this.updateWidgetDb(widget);
                } else {
                    if (this.file[this.principalEventLanguageFormated] !== widget.image[this.principalEventLanguageFormated]) {
                        await this.storage.widgetPicture(this.file[this.principalEventLanguageFormated], this.eventId, this.moduleId, storageId)
                            .then(async (principalUrl) => {
                                widget.image[this.principalEventLanguageFormated] = principalUrl;
                            })
                    }
    
                    let cont = 0;
                    for (const lang in this.file) {
                        if (lang !== this.principalEventLanguageFormated) {
                            if (this.file[lang] !== widget.image[lang]) {
                                let storageIdSecondary = null;
                                storageIdSecondary = UUID.UUID();
                                await this.storage.widgetPicture(this.file[lang], this.eventId, this.moduleId, storageIdSecondary)
                                    .then(async (urlSecondary) => {
                                        widget.image[lang] = await urlSecondary;
                                        cont++;
                                        if (cont == Object.keys(widget.image).length - 1) {
                                            this.updateWidgetDb(widget);
                                        }
                                    });
                            } else {
                                cont++;
                                if (cont == Object.keys(widget.image).length - 1) {
                                    this.updateWidgetDb(widget);
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    isEmpty(obj) {
        for (const aux in obj) {
            if (obj[aux] !== '' && obj[aux] !== undefined && obj[aux] !== null) {
                return false;
            }
        }
        return true;
    }

    // save update wiget in database
    updateWidgetDb(widget) {
        console.log(widget, widget.open_link_behavior);
        this.dbWidgets.editWidget(widget, (result) => {
            if (result['message'] == 'success') {
                this.editView = false;
                // allow to add new widget
                this.blockNewWidget = false;

                // clear properties of new widget
                this.proportion = null;
                this.file = {
                    PtBR: null,
                    EnUS: null,
                    EsES: null,
                    FrFR: null,
                    DeDE: null
                }
                this.selectedIcon = {
                    PtBR: null,
                    EnUS: null,
                    EsES: null,
                    FrFR: null,
                    DeDE: null
                };
                this.widgetRoute = "";
                this.enableExternalInput = false;
                this.widgetIsExtern = false;
                this.widgetRouteLink = null;
                this.enableStyle = false;
                this.fileName = null;
                this.base64Picture = null;
                this.borderColor = '#F0F0F0';
                this.widgetRoute = '-1';
                this.activeProportion = null;
                this.open_link_behavior = 'in-app';
                this.WidgetEditVisForLoggedUsers = null;
                this.widgetEditIndex = -1;
                // this.fileInput.nativeElement.value = '';

                // close loader
                this.loadWidgets();
                // this.loader = false;
                this.clearSubtitlesWidgets();
                if ((<HTMLInputElement>document.getElementById('customFile')) !== null) (<HTMLInputElement>document.getElementById('customFile')).value = "";
                this.successSwal.fire();
                this.fileName = null;
            } else {
                // close loader
                this.loader = false;
                this.errorSwal.fire();
            }
        });
    }

    // check before save to display errors or saving
    checkBeforeAdd() {
        this.proportionError = false;
        this.fileError = false;
        if (this.widgetRouteLink == null || this.widgetRouteLink == '-1') {
            this.widgetRouteLink = '-1';
        }

        if (this.widgetIsExtern == true) {
            this.widgetRoute = this.widgetExternUrl;
        } else if
            (this.widgetRouteLink.includes('survey') ||
            this.widgetRouteLink.includes('quiz') ||
            this.widgetRouteLink.includes('training') ||
            this.widgetRouteLink.includes('ask-question')
        ) {
            this.widgetRoute = this.widgetRouteLink;
        }

        if (this.proportion !== null) {
            if (this.file !== null && this.file !== undefined || this.selectedIcon !== null) {
                this.addWidget();
            } else {
                // selecione uma image ou icone
                this.fileError = true;
            }
        } else {
            // selecione uma proporção
            this.proportionError = true;
        }
    }

    iframeChanged(ev){
        // this.imageIframe = ev.target.value.split(' ').filter(x => x.indexOf('src') === 0)[0].split('"')[1]
        this.imageIframe = ev.target.value
        this.imageIframeSrc = this.imageIframe.split(' ').filter(x => x.indexOf('src') === 0)[0].split('"')[1]
        this.imageIframeSrcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageIframeSrc)
    }
    // add new widget
    async addWidget() {
        // // start loader
        this.loader = true;

        // check the vision type and groups.
        const groups = {}
        if (this.typeVision === this.vision_group_access_permission) {

            for (const group of this.selectedGroupCreate) {
                groups[group.uid] = group
            }
        }

        // intitialize visibility variables
        if (this.widgetVisForUnloggedUsers === undefined) {
            this.widgetVisForUnloggedUsers = false;
        }
        if (this.widgetVisForLoggedUsers === undefined) {
            this.widgetVisForLoggedUsers = false;
        }

        if(this.isIframe){
            if(this.imageIframe !== '' ){
                const iframeSrc = this.imageIframe.split(' ').filter(x => x.indexOf('src') === 0)[0].split('"')[1]

                const auxWidget = this.widgets[this.lastWidget];
                const widget: Widget = new Widget(this.proportion,
                    this.lastWidget,
                    false,
                    this.selectedIcon,
                    auxWidget.backgroundColor,
                    auxWidget.borderColor,
                    this.addBorder,
                    this.addBg,
                    this.borderIcon,
                    this.eventId,
                    this.moduleId,
                    null,
                    this.widgetRoute,
                    this.widgetIsExtern,
                    this.typeVision,
                    groups,
                    this.widgetVisForLoggedUsers,
                    this.widgetVisForUnloggedUsers,
                    this.open_link_behavior
                );
                widget.title = {
                    pt_BR: this.widgetText_ptBR,
                    en_US: this.widgetText_enUS,
                    es_ES: this.widgetText_esES,
                    fr_FR: this.widgetText_frFR,
                    de_DE: this.widgetText_deDE
                };
                widget.iconFill = auxWidget.iconFill;
                widget.subtitleTxtColor = this.subtitleTxtColor;
                widget.subtitleBgColor = this.subtitleBgColor;
                widget.gridArea = this.widgets[this.lastWidget].gridArea
                widget.isImage = true;
                widget.iframeImg = this.imageIframe !== undefined && this.imageIframe !== '' ? this.imageIframe : '';
                widget.iframeSrc = iframeSrc !== undefined && iframeSrc !== '' ? iframeSrc : '';

                // widget.widgetVisForLoggedUsers = this.widgetVisForLoggedUsers;
                this.widgets[this.lastWidget].route = this.widgetRoute;
                this.widgets[this.lastWidget].isExtern = this.widgetIsExtern;
                this.widgets[this.lastWidget].open_link_behavior = this.open_link_behavior;
                console.log(widget);
                this.saveWidgetDb(widget);

            } else {
                console.log('someting wrong')
                return ;
            }
        } else {
            // verify style (IMAGE or ICON)
            if (this.enableStyle == true) {
                // photoUrl = this.selectedIcon;
                const auxWidget = this.widgets[this.lastWidget];
                const widget: Widget = new Widget(
                    this.proportion,
                     this.lastWidget,
                      false,
                       this.selectedIcon,
                        auxWidget.backgroundColor,
                         auxWidget.borderColor,
                          this.addBorder,
                           this.addBg,
                            this.borderIcon,
                             this.eventId,
                              this.moduleId,
                               null,
                                this.widgetRoute,
                                 this.widgetIsExtern,
                                  this.typeVision,
                                   groups,
                                    this.widgetVisForLoggedUsers,
                                    this.widgetVisForUnloggedUsers,
                                     this.open_link_behavior);
                widget.title = {
                    pt_BR: this.widgetText_ptBR,
                    en_US: this.widgetText_enUS,
                    es_ES: this.widgetText_esES,
                    fr_FR: this.widgetText_frFR,
                    de_DE: this.widgetText_deDE
                };
                widget.iconFill = auxWidget.iconFill;
                widget.subtitleTxtColor = this.subtitleTxtColor;
                widget.subtitleBgColor = this.subtitleBgColor;
                widget.gridArea = this.widgets[this.lastWidget].gridArea
                // widget.widgetVisForLoggedUsers = this.widgetVisForLoggedUsers;

                this.widgets[this.lastWidget].route = this.widgetRoute;
                this.widgets[this.lastWidget].isExtern = this.widgetIsExtern;
                this.widgets[this.lastWidget].open_link_behavior = this.open_link_behavior;

                this.saveWidgetDb(widget);
            } else {
                if(this.isEmptyWidget){
                    const auxWidget = this.widgets[this.lastWidget];
                    const widget = new Widget(this.proportion,this.lastWidget,true,
                                            auxWidget.image,auxWidget.backgroundColor,auxWidget.borderColor,
                                            this.addBorder,this.addBg,this.borderIcon,
                                            this.eventId,this.moduleId,'',
                                            this.widgetRoute,this.widgetIsExtern,this.typeVision,
                                            groups,this.widgetVisForLoggedUsers,this.widgetVisForUnloggedUsers,this.open_link_behavior);

                    widget.title = {
                        pt_BR: this.widgetText_ptBR,
                        en_US: this.widgetText_enUS,
                        es_ES: this.widgetText_esES,
                        fr_FR: this.widgetText_frFR,
                        de_DE: this.widgetText_deDE
                    };
                    widget.iconFill = auxWidget.iconFill;
                    widget.subtitleTxtColor = this.subtitleTxtColor;
                    widget.subtitleBgColor = this.subtitleBgColor;
                    widget.gridArea = this.widgets[this.lastWidget].gridArea
                    this.widgets[this.lastWidget].route = this.widgetRoute;
                    this.widgets[this.lastWidget].open_link_behavior = this.open_link_behavior;
                    this.widgets[this.lastWidget].isExtern = this.widgetIsExtern;

                    widget.image = {
                        PtBR: 'assets/images/empty_widget.png',
                        EnUS: 'assets/images/empty_widget.png',
                        EsES: 'assets/images/empty_widget.png',
                        FrFR: 'assets/images/empty_widget.png',
                        DeDE: 'assets/images/empty_widget.png'
                    };

                    this.widgets[this.lastWidget].route = this.widgetRoute;
                    this.widgets[this.lastWidget].isExtern = this.widgetIsExtern;
                    this.widgets[this.lastWidget].open_link_behavior = this.open_link_behavior;

                    this.saveWidgetDb(widget);
                } else {
                // case style is IMAGE, upload to Firebase Storage and get URL
                const auxWidget = this.widgets[this.lastWidget];
                const principalImgDefault = this.checkWidgetImageIsDefault(auxWidget.image[this.principalEventLanguageFormated]);
                if (!principalImgDefault) {
                    const storageId = UUID.UUID();
                    await this.storage.widgetPicture(this.file[this.principalEventLanguageFormated], this.eventId, this.moduleId, storageId)
                        .then(async (urlPrincipal) => {
                            this.widgets[this.lastWidget].image[this.principalEventLanguageFormated] = urlPrincipal;
                            let cont = 0;

                            // tslint:disable-next-line: forin
                            for (const lang in this.widgets[this.lastWidget].image) {
                                let storageIdSecondary = null;
                                storageIdSecondary = UUID.UUID();
                                if (lang !== this.principalEventLanguageFormated) {
                                    const isDefault = this.checkWidgetImageIsDefault(this.widgets[this.lastWidget].image[lang]);
                                    if (!isDefault && this.file[lang] !== null) {
                                        await this.storage.widgetPicture(this.file[lang], this.eventId, this.moduleId, storageIdSecondary)
                                            .then(async (urlSecondary) => {
                                                this.widgets[this.lastWidget].image[lang] = await urlSecondary;
                                                cont++;
                                                if (cont == Object.keys(this.widgets[this.lastWidget].image).length - 1) {
                                                    const widget: Widget = new Widget(
                                                        this.proportion,
                                                         this.lastWidget,
                                                          true,
                                                           auxWidget.image,
                                                            auxWidget.backgroundColor,
                                                             auxWidget.borderColor,
                                                              this.addBorder,
                                                               this.addBg,
                                                                this.borderIcon,
                                                                 this.eventId,
                                                                  this.moduleId,
                                                                   storageId,
                                                                    this.widgetRoute,
                                                                     this.widgetIsExtern,
                                                                      this.typeVision,
                                                                       groups,
                                                                        this.widgetVisForLoggedUsers,
                                                                        this.widgetVisForUnloggedUsers,
                                                                         this.open_link_behavior);

                                                    widget.title = {
                                                        pt_BR: this.widgetText_ptBR,
                                                        en_US: this.widgetText_enUS,
                                                        es_ES: this.widgetText_esES,
                                                        fr_FR: this.widgetText_frFR,
                                                        de_DE: this.widgetText_deDE
                                                    };
                                                    widget.iconFill = auxWidget.iconFill;
                                                    widget.subtitleTxtColor = this.subtitleTxtColor;
                                                    widget.subtitleBgColor = this.subtitleBgColor;
                                                    widget.storageId = storageId;
                                                    widget.gridArea = this.widgets[this.lastWidget].gridArea
                                                    this.widgets[this.lastWidget].route = this.widgetRoute;
                                                    this.widgets[this.lastWidget].open_link_behavior = this.open_link_behavior;
                                                    this.widgets[this.lastWidget].isExtern = this.widgetIsExtern;
                                                    this.saveWidgetDb(widget);

                                                }
                                            })
                                    } else {
                                        this.widgets[this.lastWidget].image[lang] = await urlPrincipal;
                                        cont++;
                                        if (cont == Object.keys(this.widgets[this.lastWidget].image).length - 1) {
                                            const widget: Widget = new Widget(
                                                this.proportion,
                                                 this.lastWidget,
                                                  true,
                                                   auxWidget.image,
                                                    auxWidget.backgroundColor,
                                                     auxWidget.borderColor,
                                                      this.addBorder,
                                                       this.addBg,
                                                        this.borderIcon,
                                                         this.eventId,
                                                          this.moduleId,
                                                           storageId,
                                                            this.widgetRoute,
                                                             this.widgetIsExtern,
                                                              this.typeVision,
                                                               groups,
                                                                this.widgetVisForLoggedUsers,
                                                                this.widgetVisForUnloggedUsers,
                                                                 this.open_link_behavior);

                                            widget.title = {
                                                pt_BR: this.widgetText_ptBR,
                                                en_US: this.widgetText_enUS,
                                                es_ES: this.widgetText_esES,
                                                fr_FR: this.widgetText_frFR,
                                                de_DE: this.widgetText_deDE
                                            };
                                            widget.iconFill = auxWidget.iconFill;
                                            widget.subtitleTxtColor = this.subtitleTxtColor;
                                            widget.subtitleBgColor = this.subtitleBgColor;
                                            widget.storageId = storageId;
                                            widget.gridArea = this.widgets[this.lastWidget].gridArea
                                            this.widgets[this.lastWidget].route = this.widgetRoute;
                                            this.widgets[this.lastWidget].open_link_behavior = this.open_link_behavior;
                                            this.widgets[this.lastWidget].isExtern = this.widgetIsExtern;

                                            this.saveWidgetDb(widget);
                                        }
                                    }
                                }
                            }
                        });
                } else {
                    this.loader = false;
                    this.errorWidgetPrincipalLanguageBlank.fire();
                }
                }
            }
        }
    }

    // after get all properties of widget, save in Firestore DB - call API from dbWidgets provider
    saveWidgetDb(widget) {
        this.dbWidgets.createWidget(widget, async (status) => {
            if (status['message'] == 'success') {
                await this.successSwal.fire();
                this.adding = false;
                this.widgets[this.lastWidget].uid = status['result'];
                // close loader
                this.loader = false;
                // clear properties of new widget
                this.proportion = null;
                this.file = {
                    PtBR: '',
                    EnUS: '',
                    EsES: '',
                    FrFR: '',
                    DeDE: ''
                };
                this.selectedIcon = {
                    PtBR: '',
                    EnUS: '',
                    EsES: '',
                    FrFR: '',
                    DeDE: ''
                };
                this.fileName = null;
                this.base64Picture = null;
                this.widgetRoute = null;
                this.enableExternalInput = false;
                this.widgetIsExtern = false;
                this.editView = false;
                this.enableStyle = false;
                this.enableBorder = false;
                this.enableBg = false;
                this.widgetExternUrl = '';
                this.open_link_behavior = 'in-app';
                this.activeProportion = null;
                // this.fileInput.nativeElement.value = '';
                // allow to add new widget
                this.blockNewWidget = false;
                this.widgetRoute = '-1';
                this.typeVision = this.vision_global
                this.widgetVisForLoggedUsers = false;
                this.widgetVisForUnloggedUsers = false;
                this.selectedGroupCreate = []
                this.activeLanguageWidget = this.principalEventLanguageFormated;
                
                this.fileName = null;
                this.lastWidget = null;
                this.imageIframe = '';
                this.isEmptyWidget = false;
                // this.isIframe = false;
                this.clearSubtitlesWidgets();
                this.addForBothScreen = false;
                if ((<HTMLInputElement>document.getElementById('customFile'))) (<HTMLInputElement>document.getElementById('customFile')).value = "";
                this.loadWidgets();
            } else {
                this.loader = false;
                if (this.file !== null && this.file !== undefined) {
                    // UUID is dynamic unique UID automaticated generated
                    this.storage.deleteWidget(this.eventId, this.moduleId, this.widgetDeleteStorage);
                }
                this.errorSwal.fire();
            }
        },this.addForBothScreen, this.globalGridArea);
    }

    // change widget proportion (size)
    widgetProportion(proportion: string) {
        this.adding = true
        this.proportion = proportion; // get proportion of widget
        this.proportionError = false; // clear display error
        this.enableStyle = false;

        let gridArea = ''
        let areas = []
        const lastDesWidget = this.desktopWidgets[this.desktopWidgets.length - 1]
        if(lastDesWidget === undefined || lastDesWidget === null){
            areas = [1, 1, 1, 1]
        }else {
            areas = lastDesWidget.gridArea.split('/').map(x => Number(x.trim()))
            if(this.blockNewWidget) areas = this.desktopWidgets[this.desktopWidgets.length - 2].gridArea.split('/').map(x => Number(x.trim()))
        }
        if(this.blockNewWidget && areas[0] === 1 && areas[1] === 1) areas = [1,1,1,1]

        let [row_start, col_start,  row_end, col_end] = areas

        // check if have file uploaded before select proportion
        if (this.file[this.activeLanguageWidget] == null || this.file[this.activeLanguageWidget] == undefined || this.file[this.activeLanguageWidget] == '') {
            // add default image of proportion selected
            if (this.proportion == 'col-12 col-1row') {
                this.imageSizeDefault = this.baseImgUrl + '1024x500.png';
                this.activeProportion = 'col-12 col-1row';
                if(col_end > 5) {
                    col_start = 1
                    col_end = 5
                    row_start = row_end
                } else {
                    col_start = col_end
                    col_end = col_start + 4
                    row_start = row_start
                }
                row_end = row_start + 2
            } else if (this.proportion == 'col-6 col-1row') {
                this.imageSizeDefault = this.baseImgUrl + '500x500.png';
                this.activeProportion = 'col-6 col-1row';
                if(col_end > 7) {
                    col_start = 1
                    col_end = 3
                    row_start = row_end
                } else {
                    col_start = col_end
                    col_end = col_start + 2
                    row_start = row_start
                }
                row_end = row_start + 2
            } else if (this.proportion == 'col-6 col-2row') {
                this.imageSizeDefault = this.baseImgUrl + '500x1024.png';
                this.activeProportion = 'col-6 col-2row';
                if(col_end > 7) {
                    col_start = 1
                    col_end = 3
                    row_start = row_end
                } else {
                    col_start = col_end
                    col_end = col_start + 2
                    row_start = row_start
                }
                row_end = row_start + 4
            } else if (this.proportion == 'col-12 col-2row') {
                this.imageSizeDefault = this.baseImgUrl + '1024x1024.png';
                this.activeProportion = 'col-12 col-2row';
                if(col_end > 5) {
                    col_start = 1
                    col_end = 5
                    row_start = row_end
                } else {
                    col_start = col_end
                    col_end = col_start + 4
                    row_start = row_start
                }
                row_end = row_start + 4
            } else if (this.proportion == 'col-6 col-1row col-rounded') {
                this.imageSizeDefault = this.baseImgUrl + '500x500.png';
                this.activeProportion = 'col-6 col-1row col-rounded';
                if(col_end > 7) {
                    col_start = 1
                    col_end = 3
                    row_start = row_end
                } else {
                    col_start = col_end
                    col_end = col_start + 2
                    row_start = row_start
                }
                row_end = row_start + 2
            } else if (this.proportion == "col-6 col-05row") {  // 500x250
                this.imageSizeDefault = this.baseImgUrl + "500x250.png";
                this.activeProportion = "col-6 col-05row";
                if(col_end > 7) {
                    col_start = 1
                    col_end = 3
                    row_start = row_end
                } else {
                    col_start = col_end
                    col_end = row_start + 2
                    row_start = row_start
                }
                row_end = row_start + 1
            } else if (this.proportion == "col-12 col-05row") { // 1024x250
                this.imageSizeDefault = this.baseImgUrl + "1024x250.png";
                this.activeProportion = "col-12 col-05row";
                if(col_end > 5) {
                    col_start = 1
                    col_end = 5
                    row_start = row_end
                } else {
                    col_start = col_end
                    col_end = col_start + 4
                    row_start = row_start
                }
                row_end = row_start + 1
            }
            else if (this.proportion == "col-12 col-1row desktop") { // 2048x500
                this.imageSizeDefault = this.baseImgUrl + "2048x500.png";
                this.addForBothScreen = false;
                this.activeProportion = "col-12 col-1row desktop";
                col_start = 1
                col_end = 5
                row_start = row_end
                row_end = row_start + 1
            }
            else if (this.proportion == "col-12 col-05row desktop") { // 2048x250
                this.imageSizeDefault = this.baseImgUrl + "2048x250.png";
                this.addForBothScreen = false;
                this.activeProportion = "col-12 col-05row desktop";
                col_start = 1
                col_end = 5
                row_start = row_end
                row_end = row_start + 1
            }
            // case have file uploaded before, add the base64 and display after select proportion
        } else {
            this.imageSizeDefault = this.base64Picture;
        }

        /* verify if view is blocked to add new widget, case not blocked,
        add new widget with push (to add to array of widgets and display to user in realtime)
        */

        if (this.blockNewWidget == false) {
            const newWidget = {
                proportion: this.proportion,
                image: {
                    PtBR: this.imageSizeDefault,
                    EnUS: this.imageSizeDefault,
                    EsES: this.imageSizeDefault,
                    FrFR: this.imageSizeDefault,
                    DeDE: this.imageSizeDefault
                },
                isImage: true,
                backgroundEnable: false,
                borderEnabled: false,
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                iconFill: '#212529',
                borderSize: null,
                isExtern: false,
                title: {
                    pt_BR: '',
                    en_US: '',
                    es_ES: '',
                    fr_FR: '',
                    de_DE: ''
                },
                subtitleTxtColor: '#ffffff',
                subtitleBgColor: '#000000',
                moduleId: this.moduleId,
                gridArea:  null
            }

            gridArea =  `${row_start} / ${col_start} / ${row_end} / ${col_end}`
            this.globalGridArea = gridArea
            newWidget.gridArea = this.mobileView ?  null : gridArea
            this.widgets.push(newWidget)

            this.desktopWidgets = [...this.widgets].filter(w => w.gridArea !== undefined && w.gridArea !== null)
            this.mobileWidgets = [...this.widgets].filter(w => w.gridArea == undefined || w.gridArea == null)

            // get the index of new widgets to add to order later
            this.lastWidget = this.widgets.length - 1;
            // lock the screen to add a new widget until the current addition ends
            this.blockNewWidget = true;
        } else {
            // case view is blocked to add new widget, only change the proportion of the current widget
            this.widgets[this.widgets[this.lastWidget].proportion = this.proportion]
            this.widgets[this.lastWidget].image = {
                PtBR: this.imageSizeDefault,
                EnUS: this.imageSizeDefault,
                EsES: this.imageSizeDefault,
                FrFR: this.imageSizeDefault,
                DeDE: this.imageSizeDefault
            }
            this.desktopWidgets[this.desktopWidgets.length - 1].gridArea = `${row_start} / ${col_start} / ${row_end} / ${col_end}`
            // console.log(`${row_start} / ${col_start} / ${row_end} / ${col_end}`)
            // console.log(`${areas[0]} / ${areas[1]} / ${row_end} / ${col_end}`)
        }
    }

    // edit widget proportion (size) on edit
    widgetProportionEdit(proportion: string) {
        this.activeProportion = proportion;
        this.proportion = proportion; // get proportion of widget
        this.proportionError = false; // clear display error
        this.enableStyle = false;

        // change the proportion of the widget editing
        // this.widgets[this.widgetEditIndex].proportion = this.proportion;
        let uid = ''
        if(this.mobileView){
            this.mobileWidgets[this.widgetEditIndex].proportion = this.proportion;
            uid = this.mobileWidgets[this.widgetEditIndex].uid
        } else {
            this.desktopWidgets[this.widgetEditIndex].proportion = this.proportion;
            uid = this.desktopWidgets[this.widgetEditIndex].uid
        }
    }

    /* get widget use IMAGE or ICON: clear input file of image case select ICON
    or clear selected icon case select IMAGE */
    widgetStyle() {
        if (this.enableStyle == false) {
            this.fileInput.nativeElement.value = "";
            this.fileName = null;
            this.base64Picture = null;
            if (this.lastWidget !== null) {
                this.widgets[this.lastWidget].image[this.activeLanguageWidget] = this.imageSizeDefault;
                this.clearSubtitles();
            }
        } else {
            this.selectedIcon = {
                PtBR: null,
                EnUS: null,
                EsES: null,
                FrFR: null,
                DeDE: null
            };
            if (this.lastWidget !== null) {
                this.widgets[this.lastWidget].image[this.activeLanguageWidget] = this.imageSizeDefault;
                this.clearSubtitles();
            }
        }
    }

    /* case border is enabled, add 1px solid to show border visual to user;
    case not enabled, remove border */
    widgetBorder() {
        if (this.enableBorder == false) {
            this.borderIcon = '1px solid';
            this.addBorder = true;
            this.widgets[this.lastWidget].borderSize = this.borderIcon;
            this.widgets[this.lastWidget].borderColor = this.borderColor;
        } else {
            this.borderIcon = null;
            this.addBorder = false;
            this.widgets[this.lastWidget].borderSize = '';
            this.widgets[this.lastWidget].borderColor = 'transparent';
        }
    }

    // change background color of item (widget)
    widgetBackground() {
        if (this.enableBg == false) {
            this.addBg = true;
            this.widgets[this.lastWidget].image = '';
            this.widgets[this.lastWidget].backgroundColor = this.backgroundColor;
        } else {
            this.addBg = false;
            this.widgets[this.lastWidget].backgroundColor = 'transparent';
        }
    }

    // subtitle of item(widget) transparent
    subtitleTransparency($ev) {
        this.subtitleBgTransparency = $ev;
        if ($ev == true) {
            this.subtitleBgColor = 'transparent';
            this.widgets[this.lastWidget].subtitleBgColor = 'transparent';
        } else {
            this.subtitleBgColor = '#000000';
            this.widgets[this.lastWidget].subtitleBgColor = '#000000';
        }
    }

    // border color of widget
    widgetBorderEdit() {
        if (this.enableBorder == false) {
            this.borderIcon = '1px solid';
            this.addBorder = true;
            if(this.mobileView) this.mobileWidgets[this.widgetEditIndex].borderSize = this.borderIcon;
            else this.desktopWidgets[this.widgetEditIndex].borderSize = this.borderIcon;;

            // this.widgets[this.widgetEditIndex].borderSize = this.borderIcon;
        } else {
            this.borderIcon = null;
            this.addBorder = false;
            if(this.mobileView) this.mobileWidgets[this.widgetEditIndex].borderSize = this.borderIcon;
            else this.desktopWidgets[this.widgetEditIndex].borderSize = this.borderIcon;
            // this.widgets[this.widgetEditIndex].borderSize = this.borderColor;
        }
    }

    // widget background color on edit
    widgetBackgroundEdit() {
        if (this.enableBg == false) {
            this.addBg = true;
        } else {
            this.addBg = false;
            if(this.mobileView) this.mobileWidgets[this.widgetEditIndex].backgroundColor = 'transparent';
            else this.desktopWidgets[this.widgetEditIndex].backgroundColor = 'transparent';
            // this.widgets[this.widgetEditIndex].backgroundColor = 'transparent';
        }
    }
    // onChange funciton to get file from input file and handle image
    getWidgetImage($event: any) {
        this.file[this.activeLanguageWidget] = $event.srcElement.files[0];
        this.fileName = this.file[this.activeLanguageWidget].name;

        this.imageType = this.file[this.activeLanguageWidget].type;
        if (this.file) {
            const reader = new FileReader();
            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(this.file[this.activeLanguageWidget]);
        }
    }

    // handle image to base64 to display for use before upload to storage
    handleReaderLoaded(e) {
        let imgType: string;

        if (this.imageType == 'image/gif') {
            imgType = 'data:image/gif;base64,';
        } else if (this.imageType == 'image/png') {
            imgType = 'data:image/png;base64,';

        } else if (this.imageType == 'image/jpg') {
            imgType = 'data:image/jpg;base64,';

        } else {
            imgType = 'data:image/jpeg;base64,';
        }
        this.base64Picture = imgType + btoa(e.target.result);
        (<HTMLInputElement>document.getElementById('customFile')).value = "";
        if (this.proportion !== null) {
            this.widgets[this.lastWidget].image[this.activeLanguageWidget] = this.base64Picture;
        } else {
            this.proportionError = true;
        }
    }

    getWidgetImageEdit($event: any) {
        this.file[this.activeLanguageWidget] = $event.srcElement.files[0];
        this.fileName = this.file[this.activeLanguageWidget].name;
        this.imageType = this.file[this.activeLanguageWidget].type;
        if (this.file) {
            const reader = new FileReader();
            reader.onload = this.handleReaderLoadedEdit.bind(this);
            reader.readAsBinaryString(this.file[this.activeLanguageWidget]);
        }
    }

    handleReaderLoadedEdit(e) {
        let imgType: string;

        if (this.imageType == 'image/gif') {
            imgType = 'data:image/gif;base64,';
        } else if (this.imageType == 'image/png') {
            imgType = 'data:image/png;base64,';

        } else if (this.imageType == 'image/jpg') {
            imgType = 'data:image/jpg;base64,';

        } else {
            imgType = 'data:image/jpeg;base64,';
        }
        this.base64Picture = imgType + btoa(e.target.result);
        (<HTMLInputElement>document.getElementById('customFile')).value = "";
        if (this.proportion !== null) {
            // this.widgets[this.widgetEditIndex]['image'][this.activeLanguageWidget] = this.base64Picture;
            if(this.mobileView){
                this.mobileWidgets[this.widgetEditIndex]['image'][this.activeLanguageWidget] = this.base64Picture;
            } else {
                this.desktopWidgets[this.widgetEditIndex]['image'][this.activeLanguageWidget] = this.base64Picture;
            }
        } else {
            this.proportionError = true;
        }
    }

    // select icon from list to use instead image
    selectIcon(icon, fillIcon) {
        for (const lang in this.selectedIcon) {
            this.selectedIcon[lang] = icon.path;
        }
        // this.selectedIcon[this.activeLanguageWidget] = icon.path;
        this.widgets[this.lastWidget].isImage = false;
        this.widgets[this.lastWidget].image = this.selectedIcon;
        if (fillIcon !== undefined) {
            this.widgets[this.lastWidget].iconFill = fillIcon;
        } else {
            this.widgets[this.lastWidget].iconFill = '#212529';
        }
    }

    // iconFillChangeColor(color: string) {
    //   this.widgets[this.widgetEditIndex].iconFill = color;
    // }

    selectIconEdit(icon) {
        for (const lang in this.selectedIcon) {
            this.selectedIcon[lang] = icon.path;
        }
        // this.selectedIcon[this.activeLanguageWidget] = icon.path;
        if(this.mobileView) {
            this.mobileWidgets[this.widgetEditIndex].isImage = false;
            this.mobileWidgets[this.widgetEditIndex].image = this.selectedIcon;
        }
        else {
            this.desktopWidgets[this.widgetEditIndex].isImage = false;
            this.desktopWidgets[this.widgetEditIndex].image = this.selectedIcon;
        }
        // this.widgets[this.widgetEditIndex].isImage = false;
        // this.widgets[this.widgetEditIndex].image = this.selectedIcon;
    }

    // check widget route is intern or external and get module id if is intern
    changeWidgetPage(e) {
        this.selectSurvey = false;
        this.selectQuiz = false;
        this.selectTraining = false;
        this.selectAskQuestion = false;
        if (e.target.value == 'externalPage') {
            this.widgetRouteLink = this.widgetExternUrl;
            this.enableExternalInput = true;
            this.widgetIsExtern = true;
        } else {
            this.widgetRouteLink = '';
            this.widgetExternUrl = '';
            this.widgetRoute = e.target.value;
            if (this.widgetRoute.includes('survey')) {
                this.selectSurvey = true;
                this.loaderSurvey = true;
                this.selectQuiz = false;
                this.selectTraining = false;
                this.selectAskQuestion = false;
                this.getSurveys();
            } else if (this.widgetRoute.includes('quiz')) {
                this.selectQuiz = true;
                this.loaderQuiz = true;
                this.selectTraining = false;
                this.selectSurvey = false;
                this.selectAskQuestion = false;
                this.getQuiz();
            } else if (this.widgetRoute.includes('training')) {
                this.selectTraining = true;
                this.selectQuiz = false;
                this.selectSurvey = false;
                this.selectAskQuestion = false;
                this.getTraining();
            } else if (this.widgetRoute.includes('ask-question')) {
                this.selectAskQuestion = true;
                this.loaderAskQuestion = true;
                this.selectQuiz = false;
                this.selectTraining = false;
                this.selectSurvey = false;
                this.getAskQuestion();
            }
            this.enableExternalInput = false;
            this.widgetIsExtern = false;
        }
    }

    getWidgetDelete(widget, index) {
        this.widgetDeleteId = widget.uid;
        this.widgetDeleteIndex = index;
        this.widgetDeleteIsIcon = widget.isImage;
        if (widget.isImage == true) {
            this.widgetDeleteStorage = widget.storageId;
        } else {
            this.widgetDeleteStorage = null;
        }
    }

    deleteWidget() {
        this.loaderWidgets = true;
        this.dbWidgets.removeWidget(this.moduleId, this.widgetDeleteId, (data) => {
            if (data == true) {
                for (let i = 0; i < this.desktopWidgets.length; i++) {
                    this.desktopWidgets[i].order = i;
                }
                for (let i = 0; i < this.mobileWidgets.length; i++) {
                    this.mobileWidgets[i].order = i;
                }
                this.widgets = [...this.widgets].filter(item => item.uid !== this.widgetDeleteId)
                this.desktopWidgets = [...this.widgets].filter(w => w.gridArea !== undefined && w.gridArea !== null)
                this.mobileWidgets = [...this.widgets].filter(w => w.gridArea == undefined || w.gridArea == null)

                // this.widgets.splice(this.widgetDeleteIndex, 1);
                this.saveNewOrder();
                if (this.widgetDeleteStorage !== null) {
                    this.loaderWidgets = false;
                    this.storage.deleteWidget(this.eventId, this.moduleId, this.widgetDeleteStorage);
                } else {
                    this.loaderWidgets = false;
                }
            } else {
                this.errorSwal.fire();
                this.loaderWidgets = false;
            }
        });
    }

    // after add widget, clear fields and properties
    clearWidgetAdd() {
        this.adding = false;
        this.imageIframe = ''
        this.proportion = null;
        this.file = {
            PtBR: null,
            EnUS: null,
            EsES: null,
            FrFR: null,
            DeDE: null
        };;
        this.selectedIcon = {
            PtBR: null,
            EnUS: null,
            EsES: null,
            FrFR: null,
            DeDE: null
        };;
        this.fileName = null;
        this.base64Picture = null;
        if ((<HTMLInputElement>document.getElementById('customFile'))) (<HTMLInputElement>document.getElementById('customFile')).value = "";
        // allow to add new widget
        this.blockNewWidget = false;
        this.widgets.splice(this.lastWidget, 1);
        this.activeProportion = null;
        this.widgetRoute = '-1';
        this.enableStyle = false;
        this.selectedGroupCreate = []
        this.typeVision = this.vision_global;
        this.fileName = null;
        this.lastWidget = null;
        this.open_link_behavior = 'in-app'

        this.addForBothScreen = false;
        this.isEmptyWidget = false;

        this.desktopWidgets = [...this.widgets].filter(w => w.gridArea !== undefined && w.gridArea !== null)
        this.mobileWidgets = [...this.widgets].filter(w => w.gridArea == undefined || w.gridArea == null)
    }

    clearWidgetEdit() {
        this.editView = false;
        this.proportion = null;
        this.file = {
            PtBR: null,
            EnUS: null,
            EsES: null,
            FrFR: null,
            DeDE: null
        };;
        this.selectedIcon = {
            PtBR: null,
            EnUS: null,
            EsES: null,
            FrFR: null,
            DeDE: null
        };;
        this.fileName = null;
        this.base64Picture = null;
        if ((<HTMLInputElement>document.getElementById('customFile'))) (<HTMLInputElement>document.getElementById('customFile')).value = "";
        // allow to add new widget
        this.blockNewWidget = false;
        this.activeProportion = null;
        this.widgetRoute = '-1';
        this.enableStyle = false;
        // this.widgets[this.widgetEditIndex].image = this.widgetEditImage;
        if(this.mobileView) {
            this.mobileWidgets[this.widgetEditIndex].image = {...this.widgetEditImage};
        }
        else {
            this.desktopWidgets[this.widgetEditIndex].image = {...this.widgetEditImage};
        }
        this.isIframeEdit = false;
        this.fileName = null;
        this.selectSurvey = false;
        this.selectQuiz = false;
        this.selectTraining = false;
        this.selectAskQuestion = false;
        this.widgetIsExtern = false;
        this.open_link_behavior = 'in-app'
        this.widgetEditIndex = -1;
    }

    // change preview of item (widget) subtitle
    makePreviewSubtitle() {
        this.widgets[this.lastWidget].title['pt_BR'] = this.widgetText_ptBR;
        this.widgets[this.lastWidget].title['en_US'] = this.widgetText_enUS;
        this.widgets[this.lastWidget].title['es_ES'] = this.widgetText_esES;
        this.widgets[this.lastWidget].title['fr_FR'] = this.widgetText_frFR;
        this.widgets[this.lastWidget].title['de_DE'] = this.widgetText_deDE;
    }

    // clear subtitles
    clearSubtitles() {
        this.widgets[this.lastWidget].title['pt_BR'] = null;
        this.widgets[this.lastWidget].title['en_US'] = null;
        this.widgets[this.lastWidget].title['es_ES'] = null;
        this.widgets[this.lastWidget].title['fr_FR'] = null;
        this.widgets[this.lastWidget].title['de_DE'] = null;
    }

    clearSubtitlesWidgets() {
        this.widgetText_ptBR = null;
        this.widgetText_enUS = null;
        this.widgetText_esES = null;
        this.widgetText_frFR = null;
        this.widgetText_deDE = null;
        this.selectSurvey = false;
        this.selectQuiz = false;
        this.selectTraining = false;
        this.selectAskQuestion = false;
        this.widgetRoute = '-1';
        this.widgetRouteLink = null;
    }

    changeSubtitleTxtColor(color: string) {
        this.subtitleTxtColor = color;
    }

    changeSubtitleBgColor(color: string) {
        this.subtitleBgColor = color;
    }

    changeSubtitleTxtColorEdit(color: string) {
        this.subtitleTxtColorEdit = color;
    }

    changeSubtitleBgColorEdit(color: string) {
        this.subtitleBgColorEdit = color;
    }

    // get survey list
    getSurveys() {
        this.dbSurvey.getSurveyModuleAndSurveys(this.eventId, (response) => {
            this.listSurveys = response['surveys'];
            this.moduleIdSurvey = response['moduleId'];
            // this.widgetRoute = `/event/${this.eventId}/survey-list/${response['moduleId']}`;
            this.loaderSurvey = false;
        })
    }

    // get quiz list
    getQuiz() {
        this.dbQuiz.getQuizModuleAndQuizs(this.eventId, (response) => {
            this.listQuiz = response['quizs'];
            this.moduleIdQuiz = response['moduleId'];
            // this.widgetRoute = `/event/${this.eventId}/quiz-list/${response['moduleId']}`;
            this.loaderQuiz = false;
        });
    }

    // get training list
    getTraining() {
        this.dbTraining.getTrainingModuleAndTrainings(this.eventId, (response) => {
            this.listTraining = response['trainings'];
            this.moduleIdTraining = response['moduleId'];
            // this.widgetRoute = `/event/${this.eventId}/training-list/${response['moduleId']}`;
            this.loaderTraining = false;
        });
    }

    getAskQuestion() {
        this.dbAskQuestion.getQuestionModuleAndAskItems(this.eventId, (response) => {
            this.listAskQuestions = response['questions'];
            this.moduleIdAskQuestion = response['moduleId'];
            // this.widgetRoute = `/event/${this.eventId}/ask-question-list/${response['moduleId']}`;
            this.loaderAskQuestion = false;
        })
    }

    // select interactivity route (of loaded list)
    selectInteractivityRoute(e) {
        const aux = e.target.value;
        if (aux !== '-1') {
            this.widgetRouteLink = aux;
        }
    }

    // select item (widget) vision type
    selectTypeVision(e) {
        const aux = e.target.value;
        if (aux !== '-1') {
            this.typeVision = parseInt(aux)
        }
    }

    selectWidgetEditTypeVision(e) {
        const aux = e.target.value;
        if (aux !== '-1') {
            this.widgetEditTypeVision = parseInt(aux)
        }
    }


    // load groups event
    loadGroups() {
        this.dbGroups.searchModulesAndGroups(this.eventId, (data) => {
            this.groups = data.groups
        })
    }

    // filter groups
    filterGroupsCreate() {
        if (this.queryGroupCreate !== "") {
            this.filteredListGroupsCreate = this.groups.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroupCreate.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListGroupsCreate = [];
        }
    }

    // select track from filtered list
    selectGroupCreate(item) {
        if (this.selectedGroupCreate.length > 0) {
            const index = this.selectedGroupCreate.indexOf(item);
            if (index == -1) {
                this.selectedGroupCreate.push(item);
            }
        } else {
            this.selectedGroupCreate.push(item);
        }
        this.queryGroupCreate = '';
        this.filteredListGroupsCreate = [];
    }

    // remove selected location
    removeGroupCreate(item) {
        this.selectedGroupCreate.splice(this.selectedGroupCreate.indexOf(item), 1);
    }

    // filter groups
    filterGroupsEdit() {
        if (this.queryGroupEdit !== "") {
            this.filteredListGroupsEdit = this.groups.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroupEdit.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListGroupsEdit = [];
        }
    }

    // select track from filtered list
    selectGroupEdit(item) {
        if (this.widgetEditGroups.length > 0) {
            const index = this.widgetEditGroups.map(function (e) { return e.uid; }).indexOf(item.uid);
            if (index == -1) {
                this.widgetEditGroups.push(item);
            }
        } else {
            this.widgetEditGroups.push(item);
        }
        this.queryGroupEdit = '';
        this.filteredListGroupsEdit = [];
    }

    // remove selected location
    removeGroupEdit(item) {
        this.widgetEditGroups.splice(this.widgetEditGroups.indexOf(item), 1);
    }

    ngOnDestroy() {
        // this.dragula.destroy('bag-widget');
        this.dragula.destroy('bag-widget-mobile');
        this.dragula.destroy('bag-widget-desktop');
    }

    // check if image is default or custom
    checkWidgetImageIsDefault(image: string) {
        const dims = [
            'images/500x500',
            'images/1024x500',
            'images/350x350',
            'images/1024x1024',
            'images/500x250',
            'images/1024x250'
        ];

        return dims.some(d => image.includes(d));
    }

    // check principal language is filled
    mainLanguageIsFilled(languagesObj) {
        switch (this.event.language) {
            case 'pt_BR':
                if (languagesObj['PtBR'] !== '') {
                    return true;
                }
                break;

            case 'en_US':
                if (languagesObj['EnUS'] !== '') {
                    return true;
                }
                break;

            case 'es_ES':
                if (languagesObj['EsES'] !== '') {
                    return true;
                }
                break;

            case 'fr_FR':
                if (languagesObj['FrFR'] !== '') {
                    return true;
                }
                break;

            case 'de_DE':
                if (languagesObj['DeDE'] !== '') {
                    return true;
                }
                break;
        }

        return false;
    }

    fillEmptyLanguage(languagesObj) {
        let mainLanguageStr = null;

        switch (this.event.language) {
            case 'pt_BR':
                mainLanguageStr = languagesObj['PtBR'];
                break;

            case 'en_US':
                mainLanguageStr = languagesObj['EnUS'];
                break;

            case 'es_ES':
                mainLanguageStr = languagesObj['EsES'];
                break;

            case 'fr_FR':
                mainLanguageStr = languagesObj['FrFR'];
                break;

            case 'de_DE':
                mainLanguageStr = languagesObj['DeDE'];
                break;
        }

        if (languagesObj['PtBR'] == '') {
            languagesObj['PtBR'] = mainLanguageStr;
        }

        if (languagesObj['EnUS'] == '') {
            languagesObj['EnUS'] = mainLanguageStr;
        }

        if (languagesObj['EsES'] == '') {
            languagesObj['EsES'] = mainLanguageStr;
        }

        if (languagesObj['FrFR'] == '') {
            languagesObj['FrFR'] = mainLanguageStr;
        }

        if (languagesObj['DeDE'] == '') {
            languagesObj['DeDE'] = mainLanguageStr;
        }

        return languagesObj;
    }

    getPictureModuleBg($ev, type: string) {
        if(type==='mobile') {
            this.nameFile = $ev.target.value;
            this.displayImage = $ev.srcElement.files[0]
        } else {
            this.nameFileDesktop = $ev.target.value;
            this.displayImageDesktop = $ev.srcElement.files[0]
        }
        this.readThis($ev.target, type);
    }
    imgMobileURL = '';
    imgDesktopURL = '';
    readThis(inputValue: any, type: string): void {
        var file: File = inputValue.files[0];
        var myReader: FileReader = new FileReader();

        myReader.onloadend = (e) => {
            this.imgMobileURL = this.moduleWidget.moduleBackgroundImage
            this.imgDesktopURL = this.moduleWidget.moduleBackgroundImageDesktop
            if(type === 'mobile')
            this.moduleWidget.moduleBackgroundImage = myReader.result;
            else this.moduleWidget.moduleBackgroundImageDesktop = myReader.result;
        }
        myReader.readAsDataURL(file);
    }


    async saveModuleUpdates() {
        const result = (await this.dbEvent.updateField(
                this.eventId, 
                {
                    mobileModuleMenu: (this.mobileModuleMenu) ?
                        `widget/${this.moduleId}` : null
                }
            ));

        if (!result) {
            this.errorBgModuleUpdate = true;
            this.loaderBgUpdate = false;
            return;
        }

        const fileMobile = this.displayImage
        const fileDesktop = this.displayImageDesktop
        this.clearData();
        if (!this.moduleWidget.moduleBackgroundTypeImage) {
            this.loaderBgUpdate = true;
            this.dbWidgets.updateModule(this.eventId, this.moduleId, this.moduleWidget, this.displayImage, this.displayImageDesktop, (status) => {
                if (status) {
                    // sucesso
                    this.successBgModuleUpdate = true;
                    this.loaderBgUpdate = false;
                    setTimeout(() => {
                        this.clearData();
                    }, 3500);
                } else {
                    // erro
                    this.errorBgModuleUpdate = true;
                    this.loaderBgUpdate = false;
                }
            });
        } else {
            this.loaderBgUpdate = true;
            await this.storage.wdigetModuleBackground(fileMobile,fileDesktop,this.eventId, this.moduleId).then(urls => {
                if(urls[0] !== 'emptyMobile') this.moduleWidget.moduleBackgroundImage = urls[0]
                if(urls[1] !== 'emptyDesktop') this.moduleWidget.moduleBackgroundImageDesktop = urls[1]
                this.dbWidgets.updateModule(this.eventId, this.moduleId, this.moduleWidget, this.moduleWidget.moduleBackgroundImage, this.moduleWidget.moduleBackgroundImageDesktop, (status) => {
                    if (status) {
                        // sucesso
                        this.successBgModuleUpdate = true;
                        this.loaderBgUpdate = false;
                        setTimeout(() => {
                            this.clearData();
                        }, 3500);
                    } else {
                        // erro
                        this.errorBgModuleUpdate = true;
                        this.loaderBgUpdate = false;
                    }
                })
            })
            if(!fileMobile && !fileDesktop) {
                // selecione uma imagem
                this.selectBgModuleRequired = true;
            }
        }
    }

    clearData() {
        this.nameFile = null;
        this.nameFileDesktop = null;
        this.displayImage = null;
        this.displayImageDesktop = null;
        this.successBgModuleUpdate = false;
        this.errorBgModuleUpdate = false;
        this.selectBgModuleRequired = false;
        this.loaderBgUpdate = false;
    }

    santizeStyle(url : string) {
        return this.sanitizer.bypassSecurityTrustStyle(url);
    }

    scaleProportion(proportion: string){
        switch(proportion){
            case 'col-12 col-1row':
                return 'col-6 col-05row';
            case 'col-6 col-1row':
                return 'col-3 col-05row';
            case 'col-6 col-2row':
                return 'col-3 col-1row';
            case 'col-12 col-2row':
                return 'col-6 col-1row';
            case 'col-6 col-1row col-rounded':
                return 'col-3 col-05row col-rounded';
            case 'col-6 col-05row':
                return 'col-3 col-025row';
            case 'col-12 col-05row':
                return 'col-6 col-025row';
            case 'col-12 col-1row desktop':
                return 'col-12 col-1row';
            case 'col-12 col-05row desktop':
                return 'col-12 col-05row';
        }
    }

    changeScreen(type){
        if((type === 'mobile' && !this.mobileView) || (type === 'desktop' && this.mobileView)) {
            this.mobileView = !this.mobileView;
        }
    }

    emptyWidgetChanged(op){
        if(op === 'edit'){
            this.isEmptyWidgetEdit = !this.isEmptyWidgetEdit;
            if(this.isEmptyWidgetEdit){
                if(this.mobileView){
                    this.mobileWidgets[this.widgetEditIndex]['image'][this.activeLanguageWidget] = 'assets/images/empty_widget.png';
                } else {
                    this.desktopWidgets[this.widgetEditIndex]['image'][this.activeLanguageWidget] = 'assets/images/empty_widget.png';
                }
                // this.http.get('assets/images/empty_widget.png', {responseType: 'blob'})
                //     .subscribe(data => {
                //         this.file[this.activeLanguageWidget] = data;
                //         this.imageType = this.file[this.activeLanguageWidget].type;
                //         if (this.file) {
                //             const reader = new FileReader();
                //             reader.onload = this.handleReaderLoadedEdit.bind(this);
                //             reader.readAsBinaryString(this.file[this.activeLanguageWidget]);
                //         }
                //     })
            }
        } else {
            this.isEmptyWidget = !this.isEmptyWidget;
            this.widgets[this.lastWidget].image[this.activeLanguageWidget] = 'assets/images/empty_widget.png';
            // if(this.isEmptyWidget){
            //     this.http.get('assets/images/1024_500_vide.png', {responseType: 'blob'})
            //         .subscribe(data => {
            //             this.file[this.activeLanguageWidget] = data;
        
            //             this.imageType = this.file[this.activeLanguageWidget].type;
            //             if (this.file) {
            //                 const reader = new FileReader();
            //                 reader.onload = this.handleReaderLoaded.bind(this);
            //                 reader.readAsBinaryString(this.file[this.activeLanguageWidget]);
            //             }
        
            //         })
            // }
        }
    }

    changeWidgetImgType(type: string, op: string){
        
        if(op === 'edit'){
            console.log('edit file[this.activeLanguageWidget]', this.file[this.activeLanguageWidget])

            switch (type) {
                case 'iframe':
                    this.isIframeEdit = true;
                    this.isEmptyWidgetEdit = false;
                    break;                
            
                case 'empty':
                    this.isEmptyWidgetEdit = true;
                    this.isIframeEdit = false;
                    this.imageIframeEdit = this.imageIframeEditSrc = ''
                    this.imageIframeEditSrcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl('')
                    if(this.mobileView){
                        this.mobileWidgets[this.widgetEditIndex]['image'][this.activeLanguageWidget] = 'assets/images/empty_widget.png';
                    } else {
                        this.desktopWidgets[this.widgetEditIndex]['image'][this.activeLanguageWidget] = 'assets/images/empty_widget.png';
                    }
                    break;
                case 'image':
                    this.isIframeEdit = this.isEmptyWidgetEdit = false;
                    if(this.file[this.activeLanguageWidget] instanceof File || this.file[this.activeLanguageWidget] instanceof Blob ){ 
                        const reader = new FileReader();
                        reader.onload = this.handleReaderLoadedEdit.bind(this);
                        reader.readAsBinaryString(this.file[this.activeLanguageWidget]);
                    } else {
                        if(this.mobileView){
                            const otherLan = Object.keys(this.mobileWidgets[this.widgetEditIndex].image).filter(x => x !== this.activeLanguageWidget)[0]
                            this.mobileWidgets[this.widgetEditIndex]['image'][this.activeLanguageWidget] = this.mobileWidgets[this.widgetEditIndex]['image'][otherLan];
                        } else {
                            const otherLan = Object.keys(this.desktopWidgets[this.widgetEditIndex].image).filter(x => x !== this.activeLanguageWidget)[0]
                            this.desktopWidgets[this.widgetEditIndex]['image'][this.activeLanguageWidget] = this.desktopWidgets[this.widgetEditIndex]['image'][otherLan];
                        }
                    }
                    break;
            }
        } else {
            console.log('this.file[this.activeLanguageWidget]', this.file[this.activeLanguageWidget])
            console.log('lastWidget.image ',this.widgets[this.lastWidget].image)
            switch (type) {
                case 'iframe':
                    this.isIframe = true;
                    this.isEmptyWidget = false;
                    const otherLan = Object.keys(this.widgets[this.lastWidget].image).filter(x => x !== this.activeLanguageWidget)[0]
                    this.widgets[this.lastWidget].image[this.activeLanguageWidget] = this.widgets[this.lastWidget].image[otherLan]
                    break;
                case 'empty':
                    this.isEmptyWidget = true;
                    this.isIframe = false;
                    this.widgets[this.lastWidget].image[this.activeLanguageWidget] = 'assets/images/empty_widget.png';
                    this.imageIframeSrcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl('')
                    break;
                case 'image':
                    this.isIframe = false;
                    this.isEmptyWidget = false;
                    if (this.file[this.activeLanguageWidget]) {
                        const reader = new FileReader();
                        reader.onload = this.handleReaderLoaded.bind(this);
                        reader.readAsBinaryString(this.file[this.activeLanguageWidget]);
                    }
                    break;
            }

            // if(type === 'iframe'){
            //     this.isIframe = true;
            //     this.isEmptyWidget = false;
            //     const otherLan = Object.keys(this.widgets[this.lastWidget].image).filter(x => x !== this.activeLanguageWidget)[0]
            //     this.widgets[this.lastWidget].image[this.activeLanguageWidget] = this.widgets[this.lastWidget].image[otherLan]
    
            // } else if(type === 'empty'){
            //     this.isEmptyWidget = true;
            //     this.isIframe = false;
            //     this.widgets[this.lastWidget].image[this.activeLanguageWidget] = 'assets/images/empty_widget.png';
            //     this.imageIframeSrcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl('')
            // } else {
            //     this.isIframe = false;
            //     this.isEmptyWidget = false;
            //     if (this.file[this.activeLanguageWidget]) {
            //         const reader = new FileReader();
            //         reader.onload = this.handleReaderLoaded.bind(this);
            //         reader.readAsBinaryString(this.file[this.activeLanguageWidget]);
            //     }
            // }
        }
    }
}
